var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cp-preview" }, [
    _c("div", {
      staticClass: "cp-preview__header",
      style: `background: url(${_vm.centerLogo.logoUrl}) no-repeat top center;`,
    }),
    _c("div", { staticClass: "cp-preview__semicircle" }),
    _c("div", { staticClass: "cp-preview__semicircle-right" }),
    _c("div", { staticClass: "cp-preview__bigbg" }, [
      _c("div", { staticClass: "page-login--layer page-login--layer-area" }, [
        _c(
          "ul",
          { staticClass: "circles" },
          _vm._l(40, function (n) {
            return _c("li", { key: n })
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "guide-center" }, [
        _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
          ? _c("div", {
              staticClass: "guide-center-bottom",
              style: `background-image:url(${_vm.centerUrlInfo.iconUrl})`,
              on: {
                click: function ($event) {
                  return _vm.goUrl(_vm.centerUrlInfo.thirdUrl)
                },
              },
            })
          : _vm._e(),
        _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
          ? _c("div", { staticClass: "guide-center-guang" })
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.shownum,
              expression: "!shownum",
            },
          ],
          staticClass: "rotation3D",
          attrs: { id: "rotation3D" },
        },
        [
          _c("button", { staticClass: "center" }, [_vm._v(" 中心 ")]),
          _c(
            "div",
            { staticClass: "itemList" },
            _vm._l(_vm.levelList, function (item, index) {
              return _c(
                "div",
                { key: `list-` + index, staticClass: "rotation3D__item" },
                [
                  _c("div", { staticClass: "scale" }, [
                    item.childList
                      ? _c(
                          "div",
                          { staticClass: "small-bottom-box" },
                          _vm._l(item.childList, function (btitem, bindex) {
                            return _c(
                              "div",
                              {
                                key: `s_${bindex}`,
                                staticClass: "small-bottom-info",
                                on: {
                                  click: function ($event) {
                                    return _vm.goUrl(btitem.thirdUrl)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "bottom-big-sc" }, [
                                  _c(
                                    "div",
                                    { staticClass: "bottom-small-sc" },
                                    [
                                      btitem.iconUrl
                                        ? _c("img", {
                                            attrs: {
                                              src: btitem.iconUrl,
                                              alt: btitem.name,
                                            },
                                          })
                                        : _c("i", { staticClass: "cp-icon" }, [
                                            _vm._v(""),
                                          ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "small-bottom-txt" }, [
                                  _vm._v(" " + _vm._s(btitem.name) + " "),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "baseImg",
                      style: `background-image: url(${item.iconUrl})`,
                    }),
                    _c("div", { staticClass: "cont" }, [
                      _c("i", { staticClass: "iconfont", class: item.icon }),
                      _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(item.thirdUrl)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "main-body" },
      _vm._l(36, function (i) {
        return _c("span", {
          key: `card${i}`,
          class:
            Math.random() < 0.3
              ? "animate1"
              : Math.random() > 0.6
              ? "animate2"
              : "animate3",
          style: { opacity: Math.random() },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }