import { ajax } from '@/utils';

export default {
  /**
   * 登录
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  login(params) {
    return ajax.post('/ums/login', params);
  },

  /**
   * 获取登录用户信息
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  getUserInfo(params) {
    return ajax.get('/ums/profile', params);
  },

  /**
   * 获取侧边菜单树
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  getMenus(params) {
    return ajax.get('/ums/main/menus', params);
    // return ajax.get('/mock/menu.json', params);
  },

  /**
   * 获取系统列表
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  getSystemList(params) {
    return ajax.get('/ums/top/menus', params);
  },

  /**
   * 获取站点列表
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  getSiteList(params) {
    return ajax.get('/ums/company/list', params);
  },

  /**
   * 切换集团
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  changeGroup(params) {
    return ajax.get('/ums/switch/company', params);
  },

  /**
   * 获取系统信息，logo、图标、标题等
   * @param params
   * @return {Promise<unknown>}
   */
  getSystemInfo(params) {
    return ajax.get('setting/logo/get', params);
  },

  /**
   * 退出登录
   * @param params
   * @return {Promise<unknown>}
   */
  loginOut(params) {
    return ajax.get('ums/logout', params);
  },

  /**
   * 消息统计
   * @param params
   * @return {Promise<unknown>}
   */
  getMessageInfos(params) {
    return ajax.get('notice/statis', params);
  },

  /**
   * 公告-最新的一条公告
   * @param params
   * @return {Promise<unknown>}
   */
  getLastMessage(params) {
    return ajax.get('notice/newest', params);
  },

  /**
   * 公告-标记已读（我已知晓）
   * @param params
   * @return {Promise<unknown>}
   */
  readMessage(params) {
    return ajax.put(`notice/read/${ params }`);
  },

  /**
   * 公告-标记已读（我已知晓）
   * @param params
   * @return {Promise<unknown>}
   */
  noReadMessage(params) {
    return ajax.put(`notice/window/close/${ params }`);
  },

  /**
   * 本地部署-登录-获取短信验证码
   * @param params
   * @return {Promise<unknown>}
   */
  getVertify(params) {
    return ajax.get('local-deploy/login/sms-code', params);
  },

  /**
   * 本地部署-登录-绑定微信登录获取验证码
   * @param params
   * @return {Promise<unknown>}
   */
  getWxVertify(params) {
    return ajax.get('local-deploy/login/wx-qr/msg/code', params);
  },

  /**
   * 验证手机号是否是系统用户
   * @param params
   * @return {Promise<unknown>}
   */
  wxValidPhone(params) {
    return ajax.get('local-deploy/login/wx-qr/valid-phone', params);
  },

  /**
   * 微信openid与手机号绑定
   * @param params
   * @return {Promise<unknown>}
   */
  bindWxUserName(params) {
    return ajax.post('local-deploy/login/wx-qr/bind', params);
  },
  
  /**
   * 修改密码-获取短信验证码
   * @param params
   * @return {Promise<unknown>}
   */
  updateVertify(params) {
    return ajax.get('local-deploy/update-password/sms-code', params);
  },
  // 获取天气信息设置
  getweatherConfig() {
    return ajax.get('/screen/weather-config');
  },
  // 获取公共秘钥
  getEncryption() {
    console.log(1111);
    return ajax.get('local-deploy/login/password/encryption');
  },

  /**
   * 登录获取验证码
   * @param params
   * @return {Promise | Promise<unknown>}
   */
  getCaptcha(params) {
    return ajax.get('local-deploy/login/captcha', params);
  }
};
