/*
 * @Descripttion: 入口配置js
 * @Author: koujing
 * @Date: 2021-02-26 14:43:28
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-02 19:17:26
 */
import 'element-ui/lib/theme-chalk/index.css';
// element-ui 主题样式
// import '@/assets/scss/theme/index.css';
// 全局公共样式
import '@/assets/scss/common.scss';
import { detectZoom } from '@/plugins/detectZoom.js';
import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import { ajax } from '@/utils/ajax';
import api from '@/api';
import ElementUI from 'element-ui';
import notice from '@/utils/notice';
import preventClick from '@/utils/click-state';
// 引入图片懒加载组件
import VueLazyload from 'vue-lazyload';
// 引入自定义组件
import components from './components';
// main.js中
import animated from 'animate.css';
import * as echarts from 'echarts';
import JSEncrypt from 'jsencrypt';

Vue.use(animated);
// 解析使用AE制作的动画（需要用bodymovin导出为json格式）
import lottie from 'vue-lottie';
Vue.component('lottie', lottie);

// 引入elementUI组件
// import './plugins/element.js';

Vue.use(ElementUI, { size: 'medium' });

Vue.use(components);
Vue.use(preventClick);
// 阻止启动生产消息
Vue.config.productionTip = false;
// 挂载图片懒加载组件
Vue.use(VueLazyload);
Vue.prototype.$ajax = ajax;
Vue.prototype.$api = api;
Vue.prototype.$notice = notice;
Vue.prototype.$echarts = echarts;
Vue.prototype.$jsEncrypt = JSEncrypt;

// 动态配置网页标题
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.path.indexOf('error/') >= 0 && to.params.errorCode) {
    // 错误页面title显示错误码
    document.title = to.params.errorCode;
  } else {
    document.title = localStorage.getItem('systemTitle') || to.meta && to.meta.title || to.name;
    if (to.path === '/home') {
      const m = detectZoom();
      // 判断屏幕是否为4k window.screen.width * window.devicePixelRatio 计算分辨率
      if (window.screen.width * window.devicePixelRatio >= 3840) {
        document.body.style.zoom = 100 / (Number(m) / 2);
      } else {
        // document.body.style.zoom = 100 / (Number(m) / (window.screen.width * window.devicePixelRatio / 1920));
        document.body.style.zoom = 100 / Number(m);
      }
    } else {
      document.body.style.zoom = 1;
    }
  }
  next();
});
// 创建全局watchStorage监听
// Vue.prototype.resetSetItem = (key, newVal) => {
//   if (key === 'messageDeatil') {
//     // 创建一个StorageEvent事件
//     const newStorageEvent = document.createEvent('StorageEvent');
//     const storage = {
//       setItem(k, val) {
//         sessionStorage.setItem(k, val);
//         // 初始化创建的事件
//         newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
//         // 派发对象
//         window.dispatchEvent(newStorageEvent);
//       }
//     };
//     return storage.setItem(key, newVal);
//   }
//   return false;
// };
new Vue({
  name: 'Main',
  router,
  store,
  render: h => h(App)
}).$mount('#app');
