const mutations = {
  // 清空
  clear(state) {
    state.authCode = '';
    state.token = '';
    state.user = {
      account: {},
      umsCompany: {}
    };
    state.systemId = '';
    state.siteId = '';
    state.messageStatic = [];
    state.messageDeatil = {};
    state.refreshNum = false;
    state.showMessage = false;
    state.isThridSys = false;
  },
  token(state, payload) {
    state.token = payload;
  },
  authCode(state, payload) {
    state.authCode = payload;
  },
  // 设置用户中心个人信息
  user(state, user) {
    state.user = user;
  },
  // 设置系统id
  systemId(state, id) {
    state.systemId = id;
  },
  systemUrl(state, id) {
    state.systemUrl = id;
  },
  // 设置站点id
  siteId(state, id) {
    state.siteId = id;
  },
  independent(state, value) {
    state.independent = value;
  },
  messageStatic(state, id) {
    state.messageStatic = id;
  },
  messageDeatil(state, id) {
    state.messageDeatil = id;
  },
  refreshNum(state, id) {
    state.refreshNum = id;
  },
  showMessage(state, id) {
    state.showMessage = id;
  },
  isThridSys(state, id) {
    state.isThridSys = id;
  }
};

export default mutations;
