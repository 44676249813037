var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "light-circle-box" }, [
      _c("img", {
        staticClass: "ligth ligth1",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth2",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth3",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth4",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth5",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth6",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
      _c("img", {
        staticClass: "ligth ligth7",
        attrs: { src: require("../../../assets/images/wgs/light.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }