<template>
  <ul class="cp-side-menu-tree">
    <li v-for="menu in menuItems"
        :key="menu.mId"
        :class="{'is-extend': menu.extend}"
        class="cp-side-menu-tree__item">
      <div class="cp-side-menu-tree__item-content"
           :class="{'is-active': activeMenuId === menu.mId,
                    'is-parent': menu.children && menu.children.length}"
           @click="selectMenu(menu)">
        <span class="cp-side-menu-tree__item-title">{{ menu.label }}</span>
        <i v-if="menu.children && menu.children.length"
           class="cp-icon cp-side-menu-tree__item-extend-icon">&#xea9c;</i>
      </div>
      <cp-side-menu-tree
        v-if="menu.children && menu.children.length"
        :default-id-chain="subActiveChain"
        :menus="menu.children">
      </cp-side-menu-tree>
    </li>
  </ul>
</template>

<script>
import { cloneDeep } from 'utils-lite';
export default {
  name: 'CpSideMenuTree',
  props: {
    extend: {
      type: String,
      // all全部，first第一个叶子，none不展开
      default: 'all'
    },
    menus: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultIdChain: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      activeIdChain: [],
      activeMenuId: '',
      menuItems: []
    };
  },
  computed: {
    subActiveChain() {
      return this.activeIdChain.reduce((chain, id, index) => {
        if (index > 0) {
          chain.push(id);
        }

        return chain;
      }, []);
    }
  },
  watch: {
    defaultIdChain: {
      immediate: true,
      deep: true,
      handler(ids) {
        this.activeIdChain = cloneDeep(ids);
        this.activeMenuId = this.activeIdChain[0] || '';
      }
    },
    menus: {
      immediate: true,
      deep: true,
      handler() {
        this.menuItems = this.menus.map((menu, index) => {
          menu = cloneDeep(menu);
          if (menu.children && menu.children.length) {
            if (this.extend === 'all') {
              menu.extend = true;
            } else {
              menu.extend = this.extend === 'first' && index === 0;
            }
          } else {
            menu.extend = false;
          }

          return menu;
        });
      }
    }
  },
  methods: {
    selectMenu(menu) {
      if (menu.children && menu.children.length) {
        menu.extend = !menu.extend;
      } else if (menu.route) {
        this.$router.push({
          path: menu.route
        });
      }
    }
  }
};
</script>

<style lang="scss">
// 只设计了3层样式
@include b(side-menu-tree) {
  position: relative;
  width: 192px;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  z-index: 98;

  @include e(item) {
    position: relative;

    @include e(item-content) {
      display: flex;
      justify-content: space-between;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      color: #333;
      padding-left: 20px;
      cursor: pointer;
      user-select: none;

      @include when(active) {
        color: $primary;
        font-weight: 700;
        background-color: $--color-primary-light-9;
      }
      &:hover {
        color: $primary;
        font-weight: 700;
        background-color: $--color-primary-light-9;
      }

      @include when(parent) {
        @include when(active) {
          color: inherit;
          font-weight: 700;
          background-color: unset;
        }
        &:hover {
          color: inherit;
          font-weight: 700;
          background-color: unset;
        }
      }
      @include e(item-extend-icon) {
        &.cp-icon {
          position: relative;
          top: 2px;
          display: inline-block;
          transform: rotate(0deg);
          transition: transform .1s linear;
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }

    // 子级默认隐藏
    > .cp-side-menu-tree {
      display: none;
    }

    // 父级层展开的样式
    @include when(extend) {
      > .cp-side-menu-tree__item-content {
        font-weight: 700;

        > .cp-side-menu-tree__item-extend-icon {
          &.cp-icon {
            top: 0;
            right: 2px;
            transform: rotate(90deg);
            transition: transform .1s linear;
          }
        }
      }

      // 父级展开时子级显示
      > .cp-side-menu-tree {
        display: block;
      }
    }
  }

  // 第二层样式
  > .cp-side-menu-tree__item > .cp-side-menu-tree {
    .cp-side-menu-tree__item-content {
      height: 42px;
      line-height: 42px;
      padding-left: 32px;
      font-size: 14px;
    }

    // 第三层样式
    .cp-side-menu-tree {
      &::before {
        position: absolute;
        left: 32px;
        width: 1px;
        top: 15px;
        content: "";
        height: calc(100% - 30px);
        background-color: #e8e8e8;
        z-index: 1;
      }

      > .cp-side-menu-tree__item:hover::before {
        position: absolute;
        left: 32px;
        width: 1px;
        top: 15px;
        content: "";
        height: 15px;
        background-color: $primary;
        z-index: 2;
      }

      .cp-side-menu-tree__item-content {
        padding-left: 47px;

        @include when(active) {
          font-weight: 500;
        }
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
