var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anime-planet" }, [
      _c("div", { staticClass: "out" }, [
        _c("img", {
          staticClass: "icon icon0",
          staticStyle: { width: "1.7vw", top: "-0.9vw" },
          attrs: { src: require("../../../assets/images/wgs/out1.png") },
        }),
        _c("img", {
          staticClass: "icon icon1",
          staticStyle: { width: "2vw", top: "-1vw" },
          attrs: { src: require("../../../assets/images/wgs/out2.png") },
        }),
        _c("img", {
          staticClass: "icon icon2",
          staticStyle: { width: "2.8vw", top: "-1.3vw" },
          attrs: { src: require("../../../assets/images/wgs/out3.png") },
        }),
        _c("img", {
          staticClass: "icon icon3",
          staticStyle: { width: "2.8vw", top: "-1.4vw" },
          attrs: { src: require("../../../assets/images/wgs/out4.png") },
        }),
      ]),
      _c("div", { staticClass: "inner" }, [
        _c("img", {
          staticClass: "icon icon0",
          staticStyle: { width: "2.8vw", top: "-1.3vw" },
          attrs: { src: require("../../../assets/images/wgs/iner1.png") },
        }),
        _c("img", {
          staticClass: "icon icon1",
          staticStyle: { width: "2.4vw", top: "-1.1vw" },
          attrs: { src: require("../../../assets/images/wgs/iner2.png") },
        }),
        _c("img", {
          staticClass: "icon icon2",
          staticStyle: { width: "1.8vw", top: "-0.7vw" },
          attrs: { src: require("../../../assets/images/wgs/iner3.png") },
        }),
        _c("img", {
          staticClass: "icon icon3",
          staticStyle: { width: "1.8vw", top: "-0.75vw" },
          attrs: { src: require("../../../assets/images/wgs/iner4.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }