const state = {
  // 系统网页图标
  favicon: '',
  // 系统logo
  logo: '',
  // 系统名称
  name: '',
  // 管理系统系统logo
  globallogo: '',
  globalfavicon: '',
  globalname: ''
};

const getters = {
  logo(state) {
    return state.logo;
  },
  favicon(state) {
    return state.favicon;
  },
  name(state) {
    return state.name;
  }
};

const mutations = {
  set(state, system) {
    state.logo = system.logo;
    state.favicon = system.favicon;
    state.name = system.name;
    state.globallogo = system.globallogo;
    state.globalfavicon = system.globalfavicon;
    state.globalname = system.globalname;
  },
  logo(state, logo) {
    state.logo = logo;
  },
  favicon(state, favicon) {
    state.favicon = favicon;
  },
  name(state, name) {
    state.name = name;
  }
};

const actions = {
  // getUserInfo({ commit }) {
  //   api.getUserInfo().then((res) => {
  //     if (res.code === 0) {
  //       commit('set', res.data);
  //     }
  //   })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
