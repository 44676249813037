var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "cp-preview",
    },
    [
      [
        _c("div", { staticClass: "cp-preview__header" }, [
          _c("div", { staticClass: "cp-preview__header_left" }),
          _c("div", { staticClass: "cp-preview__header_center" }, [
            _c("img", {
              staticStyle: { "max-height": "88px" },
              attrs: { src: _vm.centerLogo.logoUrl },
            }),
          ]),
          _c("div", { staticClass: "cp-preview__header_right" }),
        ]),
        _c("div", { staticClass: "cp-preview__bigbg" }, [
          _c(
            "div",
            { staticClass: "page-login--layer page-login--layer-area" },
            [
              _c("div", { staticClass: "star" }),
              _c("div", { staticClass: "star pink" }),
              _c("div", { staticClass: "star blue" }),
              _c(
                "ul",
                { staticClass: "circles" },
                _vm._l(30, function (n) {
                  return _c("li", { key: n })
                }),
                0
              ),
              _c("div", { staticClass: "circles-box" }),
              _vm.screenWidth && _vm.isshow
                ? _c("lottie", {
                    staticClass: "lottieinfo",
                    attrs: {
                      options: _vm.defaultOptions,
                      height: 1080,
                      width: _vm.screenWidth ? _vm.screenWidth * 1 : 0,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "guide-center" }, [
            _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
              ? _c("div", {
                  staticClass: "guide-center-bottom",
                  on: {
                    click: function ($event) {
                      return _vm.goUrl(
                        _vm.centerUrlInfo.thirdUrl,
                        _vm.centerUrlInfo.newWindowFlag
                      )
                    },
                  },
                })
              : _vm._e(),
            _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
              ? _c("div", { staticClass: "guide-center-guang" })
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.shownum,
                  expression: "!shownum",
                },
              ],
              staticClass: "rotation3D",
              attrs: { id: "rotation3D" },
            },
            [
              _c("button", { staticClass: "center" }, [_vm._v(" 中心 ")]),
              _c(
                "div",
                { staticClass: "itemList" },
                _vm._l(_vm.levelList, function (item, index) {
                  return _c(
                    "div",
                    { key: `list-` + index, staticClass: "rotation3D__item" },
                    [
                      _c("div", { staticClass: "scale" }, [
                        _c("div", {
                          staticClass: "baseImg",
                          style: `background-image: url(${item.iconUrl})`,
                        }),
                        _c("div", { staticClass: "cont" }, [
                          item.childList
                            ? _c(
                                "div",
                                { staticClass: "small-bottom-box" },
                                _vm._l(
                                  item.childList,
                                  function (btitem, bindex) {
                                    return _c(
                                      "div",
                                      {
                                        key: `s_${bindex}`,
                                        staticClass: "small-bottom-info",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goUrl(
                                              btitem.thirdUrl,
                                              btitem.newWindowFlag
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "bottom-big-sc" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "bottom-small-sc",
                                              },
                                              [
                                                btitem.iconUrl
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: btitem.iconUrl,
                                                        alt: btitem.name,
                                                      },
                                                    })
                                                  : _c("img", {
                                                      attrs: {
                                                        src: require("../../../assets/images/jmg/jpjs.png"),
                                                      },
                                                    }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "small-bottom-txt" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(btitem.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "white-space": "nowrap",
                                overflow: "hidden",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    item.thirdUrl,
                                    item.newWindowFlag
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name.substr(0, 5)) + " ")]
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "main-body" },
          _vm._l(36, function (i) {
            return _c("span", {
              key: `card${i}`,
              class:
                Math.random() < 0.3
                  ? "animate1"
                  : Math.random() > 0.6
                  ? "animate2"
                  : "animate3",
              style: { opacity: Math.random() },
            })
          }),
          0
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }