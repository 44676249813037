/* Automatically generated by './build/update-router-index-file.js' */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import config from "@/config";
// error模块路由集合
import error from "./error";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: "",
    alias: "/login",
    name: config.localDeployment
      ? "景区综合管控平台登录页"
      : "景区综合管控平台跳转页",
    component: () => import("@/views/login/login.vue")
  },
  {
    path: "/index",
    name: "系统加载中......",
    component: () => import("@/views/page.vue")
  },
  {
    path: "/guide",
    name: "系统加载中......",
    component: () => import("@/views/guide/guide.vue")
  },
  {
    path: "/home",
    name: "系统加载中......",
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/redirect",
    name: "站点切换跳转",
    component: () => import("@/views/redirect/redirect.vue")
  },
  {
    path: "/weather",
    name: "仙海天气",
    component: () => import("@/views/weather/weather.vue")
  },
  {
    path: "/changePassword",
    name: "修改密码",
    component: () => import("@/views/modify/password.vue")
  },
  ...error,
  {
    path: "*",
    // 404路由，放最后
    name: "404",
    redirect: "/error/404"
  }
];
const router = new VueRouter({
  // 路由配置hash模式
  mode: "history",
  base: "/scenic",
  routes
});
router.beforeEach((to, from, next) => {
  if (to.name === "login" && !to.query.authCode && store.state.token) {
    // 登录状态下，跳转至主页
    router.push({
      path: `/index#${store.state.token}`
    });
  }
  next();
});
export default router;
