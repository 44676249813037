import Vue from 'vue';

const bus = new Vue();

export const eventBus = {
  /**
   * 注册全局事件
   * @param eventName 事件名称
   * @param handler 事件处理函数
   * @param option
   * {
   *   once 是否单次注册
   *   scope vm对象，一般传this
   * }
   */
  on(eventName, handler, option) {
    if (option.once) {
      bus.$once(eventName, handler);
      return this;
    }
    bus.$on(eventName, handler);
    if (option.scope) {
      const originalDestroy = option.scope.$destroy;
      // eslint-disable-next-line func-names
      option.scope.$destroy = function () {
        bus.$off(eventName, handler);
        originalDestroy.call(this);
      };
    }
    return this;
  },

  /**
   * 触发事件
   * @param eventName 要触发的事件名称
   * @param args 事件参数列表
   */
  emit(eventName, ...args) {
    bus.$emit(eventName, ...args);
    return this;
  },

  /**
   * 注销事件
   * @param eventName
   * @param handler
   * @return {eventBus}
   */
  off(eventName, handler) {
    bus.$off(eventName, handler);
    return this;
  }
};
