var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改密码",
        visible: _vm.changepwd,
        width: "40%",
        "custom-class": "chanagepwdbox",
        "close-on-click-modal": false,
        "modal-append-to-body": "",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.changepwd = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "ruleFormChPwd",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "原密码", prop: "oldPassword" } },
            [
              _c("el-input", {
                staticClass: "logininputs",
                attrs: {
                  maxlength: "20",
                  name: "password",
                  "show-password": "",
                  autocomplete: "new-password",
                  type: "password",
                  placeholder: "请输入原密码",
                },
                model: {
                  value: _vm.ruleForm.oldPassword,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "oldPassword",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.oldPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "newpassword" } },
            [
              _c("el-input", {
                staticClass: "logininputs",
                attrs: {
                  maxlength: "20",
                  name: "password",
                  "show-password": "",
                  autocomplete: "new-password",
                  type: "password",
                  placeholder: "请输入新密码",
                },
                model: {
                  value: _vm.ruleForm.newpassword,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "newpassword",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.newpassword",
                },
              }),
              _c("span", { staticClass: "tips" }, [
                _vm._v("8-20位由大写字母+小写字母+数字+特殊符号组成！"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认新密码", prop: "surenewpassword" } },
            [
              _c("el-input", {
                staticClass: "logininputs",
                attrs: {
                  maxlength: "20",
                  "show-password": "",
                  name: "password",
                  autocomplete: "new-password",
                  type: "password",
                  placeholder: "请确认新密码",
                },
                model: {
                  value: _vm.ruleForm.surenewpassword,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.ruleForm,
                      "surenewpassword",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "ruleForm.surenewpassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v(" 取 消 "),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sureChnagePwd } },
            [_vm._v(" 保 存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }