var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "0",
        top: "0",
        "z-index": "0",
      },
    },
    [
      _c("svg", { staticClass: "shoot", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "shoot0",
          attrs: { fill: "none", stroke: "", d: "M50.35 12.247, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot1",
          attrs: { fill: "none", stroke: "", d: "M60 13, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot2",
          attrs: { fill: "none", stroke: "", d: "M40.5 13, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot3",
          attrs: { fill: "none", stroke: "", d: "M70.5 11, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot4",
          attrs: { fill: "none", stroke: "", d: "M31 11.5, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot5",
          attrs: { fill: "none", stroke: "", d: "M75 8, L50.5 23.6" },
        }),
        _c("path", {
          staticClass: "shoot6",
          attrs: { fill: "none", stroke: "", d: "M24.5 6.5, L50.5 23.6" },
        }),
      ]),
      _c(
        "svg",
        { staticClass: "planet", attrs: { viewBox: "0 0 99.7 23.6" } },
        [
          _c("path", {
            staticClass: "planet-path1",
            attrs: {
              fill: "none",
              stroke: "",
              d: "M41.55  0,A42.9 10   0 1 1 41.55 19.6, M41.55  19.6, A43.4 10   0 0 1 41.55 0 ",
            },
          }),
          _c("path", {
            staticClass: "planet-path2",
            attrs: {
              fill: "none",
              stroke: "",
              d: "M43.45  0,A49.3 11.9 0 1 1 43.45 23.6, M43.45  23.6, A49.3 11.9 0 0 1 43.45 0",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }