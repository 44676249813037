<template>
  <div v-loading="loading" class="cp-preview">
    <template>
      <div v-if="!isBeThree" class="cp-preview__header">
        <div class="cp-preview__header_left"></div>
        <div class="cp-preview__header_center">
          <img :src="centerLogo.logoUrl" style="max-height:88px;" />
        </div>
        <div class="cp-preview__header_right"></div>
      </div>
      <div class="cp-preview__bigbg">
        <div class="page-login--layer page-login--layer-area">
          <div class="star"></div>
          <div class="star pink"></div>
          <div class="star blue"></div>
          <ul class="circles">
            <li v-for="n in 30" :key="n"></li>
          </ul>
          <div class="circles-box"></div>
        </div>
        <div class="guide-center">
          <div
            v-if="centerUrlInfo && centerUrlInfo.iconUrl"
            class="guide-center-bottom"
            @click="goUrl(centerUrlInfo.thirdUrl, centerUrlInfo.newWindowFlag)"
          ></div>
          <div
            v-if="centerUrlInfo && centerUrlInfo.iconUrl"
            class="guide-center-guang"
          ></div>
        </div>
        <div v-show="!shownum" id="rotation3D" class="rotation3D">
          <div class="itemList">
            <div
              v-for="(item, index) in levelList"
              :key="`list-` + index"
              class="rotation3D__item"
            >
              <div class="scale">
                <div
                  class="baseImg"
                  :style="`background-image: url(${item.iconUrl})`"
                ></div>
                <div class="cont">
                  <p
                    style="white-space: nowrap;overflow: hidden"
                    @click="goUrl(item.thirdUrl, item.newWindowFlag)"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-body">
        <span
          v-for="i in 36"
          :key="`card${i}`"
          :class="
            Math.random() < 0.3
              ? 'animate1'
              : Math.random() > 0.6
              ? 'animate2'
              : 'animate3'
          "
          :style="{ opacity: Math.random() }"
        >
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import screenfull from "screenfull";
export default {
  name: "SbyGuide",
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    centerInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    centerLogo: {
      type: Object,
      default() {
        return {};
      }
    },
    screenWidth: {
      type: String,
      default: ""
    },
    screenHeight: {
      type: String,
      default: ""
    },
    isshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: true,
      isBeThree: false,
      // 拖动位置
      moveDataelse: {
        x: null,
        y: null
      },
      loading: false,
      url: "",
      dialogVisible: false,
      defaultOptions: {
        animationData: {
          v: "5.5.6",
          fr: 25,
          ip: 0,
          op: 300,
          w: 1920,
          h: 1080,
          nm: "剑门关导览页动效",
          ddd: 0,
          assets: [
            {
              id: "image_0",
              w: 1053,
              h: 1053,
              u: "images/",
              p: "img_0.png",
              e: 0
            }
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 2,
              nm: "椭圆.png",
              cl: "png",
              refId: "image_0",
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667],
                        y: [1]
                      },
                      o: {
                        x: [0.333],
                        y: [0]
                      },
                      t: 0,
                      s: [0]
                    },
                    {
                      i: {
                        x: [0.667],
                        y: [1]
                      },
                      o: {
                        x: [0.333],
                        y: [0]
                      },
                      t: 53,
                      s: [100]
                    },
                    {
                      t: 75,
                      s: [0]
                    }
                  ],
                  ix: 11,
                  x: "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
                },
                r: {
                  a: 0,
                  k: 0,
                  ix: 10
                },
                p: {
                  a: 0,
                  k: [960, 766, 0],
                  ix: 2
                },
                a: {
                  a: 0,
                  k: [526.5, 526.5, 0],
                  ix: 1
                },
                s: {
                  a: 1,
                  k: [
                    {
                      i: {
                        x: [0.667, 0.667, 0.667],
                        y: [1, 1, 1]
                      },
                      o: {
                        x: [0.611, 0.611, 0.333],
                        y: [0, 0, 0]
                      },
                      t: 0,
                      s: [0, 0, 100]
                    },
                    {
                      t: 75,
                      s: [141.246, 35, 100]
                    }
                  ],
                  ix: 6,
                  x: "var $bm_rt;\n$bm_rt = loopOutDuration('cycle', 0);"
                }
              },
              ao: 0,
              ip: 0,
              op: 300,
              st: 0,
              bm: 0
            }
          ],
          markers: []
        }
      },
      rotation3D: null,
      shownum: false,
      centerUrlInfo: {},
      firstTime: ""
    };
  },
  watch: {
    levelList: {
      handler(val) {
        if (val && val.length > 0) {
          this.showAnimation();
        }
      },
      deep: true,
      immediate: true
    },
    centerInfo: {
      handler(val) {
        this.centerUrlInfo = val;
      },
      deep: true,
      immediate: true
    },
    dialogVisible() {
      // this.showAnimation();
      const iframDiv = document.getElementById("gameFrameparent");
      iframDiv.style.pointerEvents = "none";
    }
  },
  beforeDestroy() {
    if (this.rotation3D) {
      this.rotation3D.close();
      this.rotation3D = null;
    }
  },
  mounted() {
    const abc = sessionStorage.getItem("isthridSys");
    if (abc && Number(abc) === 1) {
      this.isBeThree = true;
    } else {
      this.isBeThree = false;
    }
    this.showAnimation();
  },
  methods: {
    mouseDownHandleelse(event) {
      this.firstTime = new Date().getTime();
      const iframDiv = document.getElementById("gameFrameparent");
      iframDiv.style.pointerEvents = "none";
      this.moveDataelse.x = event.pageX - this.$refs.eventdetail.offsetLeft;
      this.moveDataelse.y = event.pageY - this.$refs.eventdetail.offsetTop;
      // event.currentTarget.style.cursor = 'move';
      window.onmousemove = this.mouseMoveHandleelse;
    },
    mouseMoveHandleelse(event) {
      // 事件详情才能拖动
      const offsetwidth = this.$refs.eventdetail.offsetWidth;
      const offsetheight = this.$refs.eventdetail.offsetHeight;
      // 浏览器宽高
      const clientwidth = document.documentElement.clientWidth;
      const clientheight = document.documentElement.clientHeight;
      let moveLeft = event.pageX - this.moveDataelse.x;
      let moveTop = event.pageY - this.moveDataelse.y;
      if (moveLeft < 0) {
        moveLeft = 0;
      } else if (clientwidth < moveLeft + offsetwidth) {
        moveLeft = clientwidth - offsetwidth;
      }
      if (moveTop < 0) {
        moveTop = 0;
      } else if (clientheight < moveTop + offsetheight) {
        moveTop = clientheight - offsetheight;
      }

      this.$refs.eventdetail.style.left = `${moveLeft}px`;
      this.$refs.eventdetail.style.top = `${moveTop}px`;
    },
    mouseUpHandleelse() {
      const iframDiv = document.getElementById("gameFrameparent");
      iframDiv.style.pointerEvents = "auto";
      window.onmousemove = null;
      const lastTime = new Date().getTime();
      if (lastTime - this.firstTime < 200) {
        this.dialogVisible = false;
      }
    },
    fullScreen() {
      screenfull.request();
      this.active = !this.active;
    },
    exitFullSreen() {
      screenfull.exit();
      this.active = !this.active;
    },
    refreshIframe() {
      if (this.loading) {
        return;
      }
      // 加载
      this.loading = true;
      // 控制页面
      this.dialogVisible = false;
      setTimeout(() => {
        this.dialogVisible = true;
      }, 200);
      // 为了防止加载loading过多，默认给一个2s延迟
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    // 加载动画
    showAnimation() {
      if (this.shownum || this.levelList.length === 0) {
        return;
      }
      this.shownum = true;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.rotation3D) {
            this.rotation3D.close();
            this.rotation3D = null;
          }
          // eslint-disable-next-line no-undef
          this.rotation3D = new Rotation3D({
            id: "#rotation3D",
            farScale: 0.4,
            // farScale: 1,
            // x半径压缩
            xRadius: 0,
            // y半径压缩
            yRadius: 260,
            // yRadius: 0,  //y半径压缩
            autoPlay: true,
            autoPlayDelay: 6000
          });
          this.shownum = false;
          this.rotation3D.goTo(2);
        }, 1000);
      });
    },
    // 打开新窗口页面
    goUrl(url, newWindowFlag) {
      if (!url) return;
      if (newWindowFlag == 0) {
        location.href = url;
      } else if (newWindowFlag == 1) {
        window.open(url);
      } else if (newWindowFlag == 2) {
        sessionStorage.setItem('iframe', true);
        this.dialogVisible = false;
        this.loading = true;
        this.url = url;
        this.dialogVisible = true;
        setTimeout(() => {
          this.dialogVisible = true;
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./sby-guide.scss";
.frame {
  position: absolute;
  z-index: 999;
  border: none;
}
.btn {
  position: fixed;
  z-index: 9999;
  // width: 150px;
  // height: 60px;
  width: 80px;
  height: 80px;
  background: #0d182f;
  border-radius: 50%;
  right: 400px;
  top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  opacity: 0.4;
  cursor: default;
  i {
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
  }
}
</style>
