<template>
  <div
    class="anime-box"
    :style="[{width: clientWidth + 'px'}, {height: clientHeight + 'px'}]">
    <anime-bg></anime-bg>
    <div class="header">
      <img src="../../../assets/images/wgs/txt.png">
    </div>
    <lottie
      class="lottieinfo"
      :options="defaultOptions"
      :height="clientHeight"
      :width="clientWidth"></lottie>
    <div class="content">
      <anime-svg></anime-svg>
      <anime-svg-shoot></anime-svg-shoot>
      <anime-rocket :center-info="centerInfo"></anime-rocket>
      <anime-menu-first
        :level-list="navlist"
        @firstIconClick="firstIconClick"></anime-menu-first>
      <anime-menu-child ref="menuChild"
                        :shoot-runing="shootRuning"></anime-menu-child>
      <img
        class="shooticon"
        :style="{visibility: shootRuning ? 'visible' : 'hidden'}"
        src="../../../assets/images/wgs/dz.png">
      <anime-planet></anime-planet>
      <anime-light-circle></anime-light-circle>
    </div>
    <anime-particle></anime-particle>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';
import animeBg from './anime-bg.vue';
import animeSvg from './anime-svg.vue';
import animeRocket from './anime-rocket.vue';
import animeSvgShoot from './anime-svg-shoot.vue';
import AnimeMenuFirst from './anime-menu-first.vue';
import AnimeMenuChild from './anime-menu-child.vue';
import dataParticle from './particle-json';
import animeParticle from './anime-particle';
import animePlanet from './anime-planet';
import animeLightCircle from './anime-light-circle';
export default {
  name: 'WgsGuide',
  components: {
    animeSvg,
    AnimeMenuFirst,
    animeSvgShoot,
    animeRocket,
    animeParticle,
    animePlanet,
    animeLightCircle,
    AnimeMenuChild,
    animeBg
  },
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    centerInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    centerLogo: {
      type: Object,
      default() {
        return {};
      }
    },
    screenWidth: {
      type: String,
      default: ''
    },
    screenHeight: {
      type: String,
      default: ''
    },
    isshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      navlist: [],
      clientWidth: 0,
      clientHeight: 0,
      shootRuning: false,
      defaultOptions: {
        animationData: dataParticle
      }
    };
  },
  watch: {
    levelList: {
      handler(val) {
        if (val && val.length > 0) {
          this.navlist.forEach((im) => {
            im.active = false;
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    this.navlist = this.levelList;
  },
  methods: {
    firstIconClick(shootIndex) {
      this.$refs.menuChild.resetData(this.navlist[shootIndex].childList);
      this.shootRuning = true;
      this.playShoot(shootIndex);
      this.$refs.menuChild.clearAnime();
    },
    playShoot(index) {
      const d = 300;
      const path = anime.path(`.shoot .shoot${ index }`);
      anime({
        targets: '.shooticon',
        translateX: path('x'),
        translateY: path('y'),
        rotate: () => {
          if (index === 1) {
            return 45;
          } else if (index === 2) {
            return -45;
          } else if (index === 3) {
            return 60;
          } else if (index === 4) {
            return -60;
          } else if (index === 5) {
            return 60;
          } else if (index === 6) {
            return -60;
          } else {
            return 0;
          }
        },
        opacity: [
          { value: 0,
            duration: d / 6 },
          { value: 1,
            duration: d / 2 },
          { value: 0,
            duration: d / 3 }
        ],
        duration: d,
        easing: 'easeOutCirc',
        complete: () => {
          this.shootRuning = false;
          anime.set('.shooticon', { opacity: 1,
                                    rotate: 0 });
          this.$nextTick(() => {
            this.$refs.menuChild.playAnime();
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.anime-box {
  // background: url("../../../assets/images/wgs/bg.png") no-repeat;
  // background-size: auto 100%;
  // background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .header {
    position: absolute;
    top: 0;
    height: 117px;
    z-index: 10;
    @media screen and (max-width: 1920px) {
      height: 64px;
    }
    width: 100%;
    background: url("../../../assets/images/wgs/top.png") no-repeat;
    background-size: cover;
    background-position: center;
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 50%;
      margin: auto;
    }
  }
  .lottieinfo {
    position: absolute;
    top: -10%;
    left: 0;
    z-index: 9;
    background: transparent;
  }
  .content {
    width: 66.47vw;
    height: 15.725vw;
    position: relative;
    background: url(../../../assets/images/wgs/line.png);
    background-size: 100% 100%;
    z-index: 9;
    top:5vh;
    .shooticon {
      position: absolute;
      top: -1.8vw;
      left: -1.15vw;
      // width: 68px;
    }
    & svg {
      width: 100%;
      height: 100%;
    }

  }
}
</style>
