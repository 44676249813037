<template>
  <section v-if="centerInfo && centerInfo.iconUrl">
    <div class="rocket">
      <img class="head"
           src="@/assets/images/wgs/rocket.png"
           @click="goUrl(centerInfo.thirdUrl)">
      <br>
      <img class="light"
           src="@/assets/images/wgs/rocket_.png">
    </div>
  </section>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeRocket',
  props: {
    centerInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      animeCase: null
    };
  },
  watch: {
    centerInfo: {
      handler() {
        this.$nextTick(() => {
          this.playRocket();
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    playRocket() {
      anime({
        targets: '.rocket .head',
        translateY: '+=1.5vw',
        duration: 3000,
        easing: 'easeInOutQuart',
        direction: 'alternate',
        loop: true
      });
      anime({
        targets: '.rocket .light',
        translateY: '+=1.5vw',
        opacity: 0.3,
        duration: 3000,
        easing: 'easeInOutQuart',
        direction: 'alternate',
        loop: true
      });
    },
    // 打开新窗口页面
    goUrl(url) {
      if (url) {
        window.open(url);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.rocket {
  img {
    width: 5vw;
  }
  position: absolute;
  top: -4.5vw;
  left: 0.8vw;
  right: 0;
  width: 5.78125vw;
  margin: auto;
  .head {
    position: relative;
    z-index:10;
    cursor: pointer;
  }
  .light {
    position: relative;
    top: -1.7vw;
    z-index: 7;
  }
}
</style>
