var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cp-sidebar",
      class: { "cp-sidebar--has-children": _vm.subMenus.length },
    },
    [
      _c("div", { staticClass: "cp-side-top-menu" }, [
        _c(
          "div",
          { staticClass: "cp-side-container", class: { active: _vm.click } },
          [
            _vm._m(0),
            _c(
              "ul",
              { staticClass: "cp-side-menu" },
              _vm._l(_vm.invalidMenus, function (menu) {
                return _c(
                  "li",
                  {
                    key: menu.mId,
                    staticClass: "cp-side-menu__item",
                    class: { "is-active": _vm.activeMenuId === menu.mId },
                    on: {
                      click: function ($event) {
                        return _vm.selectMenu(menu)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "cp-icon cp-side-menu__item-icon",
                      domProps: { innerHTML: _vm._s(menu.icon) },
                    }),
                    _c("span", { staticClass: "cp-side-menu__item-title" }, [
                      _vm._v(_vm._s(menu.label)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _vm.subMenus.length
        ? _c("cp-side-menu-tree", {
            attrs: {
              "default-id-chain": _vm.subActiveChain,
              menus: _vm.subMenus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cp-side-menu-extend-btn" }, [
      _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }