<template>
  <div style="width:100%;height:100%;position: relative;">
    <svg class="path1"
         viewBox="0 0 99.7 23.6">
      <!-- 右一 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.247, Q57.1583 22.3,65.56666 21.7"></path>
    </svg>
    <svg class="path3"
         viewBox="0 0 99.7 23.6">
      <!-- 右二 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.247, Q57.1583 22.3,65.56666 21.7 M65.56666 21.7,Q72.65 21.3,82.18326 19.5"
        class="pathRt2"></path>
    </svg>
    <svg class="path5"
         viewBox="0 0 99.7 23.6">
      <!-- 右三 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.247, Q57.1583 22.3,65.56666 21.7 M65.56666 21.7,Q72.65 21.3,82.18326 19.5 M82.18326 19.5, C91.5 17.5,100 14, 94.7 8 "
        class="pathRt3"></path>
    </svg>
    <svg class="path2"
         viewBox="0 0 99.7 23.6">
      <!-- 左一 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.25,Q41.5417 22.3,33.2334 21.6 "
        class="pathLt1"></path>
    </svg>
    <svg class="path4"
         viewBox="0 0 99.7 23.6">
      <!-- 左二 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.25,Q41.5417 22.3,33.2334 21.6 M33.2334 21.6, Q24.8 21,16.6166 19.258"
        class="pathLt2"></path>
    </svg>
    <svg class="path6"
         viewBox="0 0 99.7 23.6">
      <!-- 左三 -->
      <path
        fill="none"
        stroke=""
        d="M49.85 22.25,Q41.5417 22.3,33.2334 21.6 M33.2334 21.6, Q24.8 21,16.6166 19.258 M16.6166 19.258, C5.5 16.7,0.5 13.2,5 8 "
        class="pathLt3"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AnimeSvg'
};
</script>

<style scoped lang="scss">
svg{
  position: absolute;
  left: 0;
}
</style>
