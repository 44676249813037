/*
 * @Descripttion:用于控制按钮重复点击
 * @Author: koujing
 * @Date: 2021-04-09 11:45:44
 * @LastEditors: koujing
 * @LastEditTime: 2021-04-09 11:54:29
 */
export default {
  install(Vue) {
    // 防止重复点击[默认3s点击下一次]
    Vue.directive('preventClick', {
      inserted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding.value || 3000);
          }
        });
      }
    });
  }
};

