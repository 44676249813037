var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.centerInfo && _vm.centerInfo.iconUrl
    ? _c("section", [
        _c("div", { staticClass: "rocket" }, [
          _c("img", {
            staticClass: "head",
            attrs: { src: require("@/assets/images/wgs/rocket.png") },
            on: {
              click: function ($event) {
                return _vm.goUrl(_vm.centerInfo.thirdUrl)
              },
            },
          }),
          _c("br"),
          _c("img", {
            staticClass: "light",
            attrs: { src: require("@/assets/images/wgs/rocket_.png") },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }