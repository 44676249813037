var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "cp-side-menu-tree" },
    _vm._l(_vm.menuItems, function (menu) {
      return _c(
        "li",
        {
          key: menu.mId,
          staticClass: "cp-side-menu-tree__item",
          class: { "is-extend": menu.extend },
        },
        [
          _c(
            "div",
            {
              staticClass: "cp-side-menu-tree__item-content",
              class: {
                "is-active": _vm.activeMenuId === menu.mId,
                "is-parent": menu.children && menu.children.length,
              },
              on: {
                click: function ($event) {
                  return _vm.selectMenu(menu)
                },
              },
            },
            [
              _c("span", { staticClass: "cp-side-menu-tree__item-title" }, [
                _vm._v(_vm._s(menu.label)),
              ]),
              menu.children && menu.children.length
                ? _c(
                    "i",
                    {
                      staticClass:
                        "cp-icon cp-side-menu-tree__item-extend-icon",
                    },
                    [_vm._v("")]
                  )
                : _vm._e(),
            ]
          ),
          menu.children && menu.children.length
            ? _c("cp-side-menu-tree", {
                attrs: {
                  "default-id-chain": _vm.subActiveChain,
                  menus: menu.children,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }