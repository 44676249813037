/*
 * @Descripttion: 全站参数配置js
 * @Author: koujing
 * @Date: 2021-02-26 17:58:17
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-01 14:26:16
 */
/* eslint-disable */
export default {
    // 系统名称
    systemTitle: '',
    // 系统中上传文件需要的key值，可使用原景区管控平台key值或向黄新凯索取
    uploadKey: '',
    upload: {
        url: '//file.geeker.com.cn/uploadOSS',
        key: 'WN1EX00HLL8Y0O0K',
        path: 'cloud-platform',
        name: 'Filedata'
    },
    // 开发阶段字段。后台接口所需要的token，登录时由后台返回给前台，若没有则使用当前配置的token
    token: '',
    // vuex保存localstorage的字段名称，景区管控平台：集团版和景区版各个子系统通用
    storageName: '_scenic-management-platform',
    // 接口需要的token名称（值为登录时获取）
    tokenName: 'Authorization'
};
/* eslint-disable */