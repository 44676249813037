<template>
  <section class="flex flex-c  wgs-guide-second font-30 col-fefeff">
    <div v-if="dialogVisible">
      <iframe id="gameFrameparent"
              v-loading.fullscreen.lock="loading"
              element-loading-text="正在加载，请稍后"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              class="frame"
              :src="url"
              width="100%"
              height="100%"
              @load="loading = false"></iframe>
      <div ref="eventdetail"
           class="btn"
           @mousedown="mouseDownHandleelse($event)"
           @mouseup="mouseUpHandleelse( $event)">
        <i class="cp-icon"
           title="返回导览页">&#xeb54;</i>
        <!-- <i class="el-icon-refresh"
           title="刷新"
           @click="refreshIframe"></i>
        <i v-if="active"
           title="全屏"
           style="font-size:19px"
           class="cp-icon"
           @click="fullScreen">&#xeb0a;</i> -->
        <!-- <i v-else
           style="font-size:22px"
           class="cp-icon"
           title="退出全屏"
           @click="exitFullSreen">&#xebc0;</i> -->
      </div>
    </div>
    <template v-else>
      <div style="text-align:center;margin-top:1.5rem;margin-bottom:0.7rem">
        <img :src="centerLogo.logoUrl"
             class="flex-noshk">
      </div>
      <section class="flex flex1 content flex-c flex-just-center  flex-align-center">
        <section class="flex main">
          <div v-if="centerInfo.name"
               class="flex flex-c flex-align-center flex-just-center center"
               @click="goUrl(centerInfo.thirdUrl,centerInfo.newWindowFlag,centerInfo.name)">
            <img :src="centerInfo.iconUrl">
            <label>{{ centerInfo.name }}</label>
          </div>
          <ul class="flex">
            <section v-for="(list,index) in levelListFomart"
                     :key="index">
              <li v-for="(item,zindex) in list"
                  v-show="item.thirdUrl || item.name || item.iconUrl"
                  :key="item.id"
                  class="flex flex-c flex-align-center flex-just-center list"
                  :style="{background: colors[index * 2 + zindex]}"
                  @click="goUrl(item.thirdUrl,item.newWindowFlag,item.name)">
                <img :src="item.iconUrl">
                <label>{{ item.name }}</label>
              </li>
            </section>
          </ul>
        </section>
        <ul v-if="isWgs"
            class="flex code">
          <li class="flex flex-c flex-align-center">
            <img src="@/assets/images/wgs-second/code_yjy.png">
            <label>一机游</label>
          </li>
          <li class="flex flex-c flex-align-center">
            <img src="@/assets/images/wgs-second/code_app.png">
            <label>一机管APP下载</label>
          </li>
          <li class="flex flex-c flex-align-center">
            <img src="@/assets/images/wgs-second/code_wx.jpg">
            <label>官方微信</label>
          </li>
        </ul>
      </section>
    </template>
  </section>
</template>

<script>
export default {
  name: 'WgsGuideSecond',
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    centerInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    centerLogo: {
      type: Object,
      default() {
        return {};
      }
    },
    screenWidth: {
      type: String,
      default: ''
    },
    screenHeight: {
      type: String,
      default: ''
    },
    isshow: {
      type: Boolean,
      default: true
    },
    isIframe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isWgs: window.scenicManagementPlatformConfig.projectName === 'wugongshan',
      navlist: [],
      url: '',
      loading: true,
      // 拖动位置
      moveDataelse: {
        x: null,
        y: null
      },
      firstTime: '',
      dialogVisible: false,
      colors: ['#44b365', '#3cb2c9', '#8870ff', '#3f93f2', '#37a3e6', '#41ba8c',
               '#e38664', '#db5ecd', '#72b849', '#b463f2', '#e57373', '#e0ab4f'
      ]
    };
  },
  computed: {
    levelListFomart() {
      const list = [];
      let zindex = 0;
      let temp = [];
      this.navlist.forEach((item) => {
        if (zindex <= 1) {
          temp.push(item);
        }
        if (zindex === 1) {
          list.push(temp);
          temp = [];
          zindex = 0;
        } else {
          zindex++;
        }
      });

      return list;
    }
  },
  watch: {
    levelList: {
      handler(val) {
        this.navlist = JSON.parse(JSON.stringify(val));
        if (val.length % 2 === 1) {
          this.navlist.push({});
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    const rWidth = 3840;
    const clientWidth = document.documentElement.clientWidth;
    document.documentElement.style.fontSize = `${ 100 * (clientWidth / rWidth) }px`;
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', this.popstate, false);
  },
  destroyed() {
    document.documentElement.style.fontSize = '16px';
    window.removeEventListener('popstate', this.popstate, false);
  },
  methods: {
    popstate() {
      if (this.dialogVisible) {
        this.dialogVisible = false;
        sessionStorage.removeItem('iframe');
        this.$emit('refresh', true);
        window.history.pushState(null, null, document.URL);
      }
    },
    upgradeHttp() {
      this.loading = false;
      this.dialogVisible = false;
      // const subtitle = '是否确定删除该服务器？';
      const content = '浏览器内部窗口不支持HTTP链接，请升级为HTTPS后访问！';
      const title = '温馨提示';
      this.$alert(`<div class="daq-message-box">
            <p class="content">${ content }</p>
          </div>`, {
          title,
          showCancelButton: false,
          customClass: 'globalAlertBox',
          dangerouslyUseHTMLString: true,
          confirmButtonText: '知道了',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              done();
            } else {
              done();
            }
          }
      });
    },
    iframeError(e) {
      console.log('e: ', e);
      if (e && this.url.substring(0, 5) !== 'https') {
        this.upgradeHttp();
      }
    },
    mouseDownHandleelse(event) {
      this.firstTime = new Date().getTime();
      const iframDiv = document.getElementById('gameFrameparent');
      iframDiv.style.pointerEvents = 'none';
      this.moveDataelse.x = event.pageX - this.$refs.eventdetail.offsetLeft;
      this.moveDataelse.y = event.pageY - this.$refs.eventdetail.offsetTop;
      // event.currentTarget.style.cursor = 'move';
      window.onmousemove = this.mouseMoveHandleelse;
    },
    mouseMoveHandleelse(event) {
      // 事件详情才能拖动
      const offsetwidth = this.$refs.eventdetail.offsetWidth;
      const offsetheight = this.$refs.eventdetail.offsetHeight;
      // 浏览器宽高
      const clientwidth = document.documentElement.clientWidth;
      const clientheight = document.documentElement.clientHeight;
      let moveLeft = event.pageX - this.moveDataelse.x;
      let moveTop = event.pageY - this.moveDataelse.y;
      if (moveLeft < 0) {
        moveLeft = 0;
      } else if (clientwidth < moveLeft + offsetwidth) {
        moveLeft = clientwidth - offsetwidth;
      }
      if (moveTop < 0) {
        moveTop = 0;
      } else if (clientheight < moveTop + offsetheight) {
        moveTop = clientheight - offsetheight;
      }

      this.$refs.eventdetail.style.left = `${ moveLeft }px`;
      this.$refs.eventdetail.style.top = `${ moveTop }px`;
    },
    mouseUpHandleelse() {
      const iframDiv = document.getElementById('gameFrameparent');
      iframDiv.style.pointerEvents = 'auto';
      window.onmousemove = null;
      const lastTime = new Date().getTime();
      if (lastTime - this.firstTime < 200) {
        this.dialogVisible = false;
        sessionStorage.removeItem('iframe');
        this.$emit('refresh', true);
      }
    },
    // 打开新窗口页面
    goUrl(url, newWindowFlag) {
      if (url) {
        if (newWindowFlag) {
          window.open(url);
        } else {
          // if (url.substring(0, 5) !== 'https') {
          //   this.upgradeHttp();
          //   return;
          // }
          sessionStorage.setItem('iframe', true);
          this.dialogVisible = false;
          this.loading = true;
          this.url = url;
          this.dialogVisible = true;
          setTimeout(() => {
            this.dialogVisible = true;
          }, 100);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.frame {
  position: absolute;
  z-index: 999;
  border: none;
}
.btn {
  position: fixed;
  z-index: 9999;
  // width: 150px;
  // height: 60px;
  width: 60px;
  height: 60px;
  background: #0d182f;
  border-radius: 50%;
  right: 400px;
  top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  opacity: 0.4;
  cursor: default;
  i {
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
  }
}
.flex1 {
  flex: 1;
}
.col-fefeff {
  color: #fefeff;
}
.font-30 {
  font-size: 0.3rem;
}
.flex-noshk {
  flex-shrink: 0;
}
.flex {
  display: flex;
}
.flex-c {
  flex-direction: column;
}
.flex-align-center {
  align-items: center;
}
.flex-just-center {
  justify-content: center;
}
.wgs-guide-second {
  height: 100%;
  width: 100%;
  background: url(../../../assets/images/wgs-second/bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  .content {
    .main {
      .center {
        cursor: pointer;
        width: 3.6rem;
        height: 5.1rem;
        background: #6473fa;
        border-radius: 0.08rem;
        margin-right: 0.3rem;
        overflow: hidden;
        & > img {
          height: 0.52rem;
          margin-bottom: 0.1rem;
        }
      }
      .center:hover {
        transform: scale(1.05);
      }
      .list {
        cursor: pointer;
        width: 3.6rem;
        height: 2.4rem;
        margin-bottom: 0.3rem;
        background: #6473fa;
        margin-right: 0.3rem;
        border-radius: 0.08rem;
        & > img {
          height: 0.52rem;
          margin-bottom: 0.1rem;
        }
      }
      .list:hover {
        transform: scale(1.05);
      }
    }
  }
  .code {
    justify-content: space-between;
    font-size: 0.3rem;
    color: #fefeff;
    margin-top: 1.1rem;
    padding-bottom: 0.3rem;
    & > li {
      margin-left: 1.9rem;
      overflow: hidden;
      & > img {
        margin-bottom: 15px;
        width: 2rem;
        height: 2rem;
      }
    }
    & > li:first-child {
      margin-left: 0;
    }
  }
}
</style>
