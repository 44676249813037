var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dialogVisible
        ? [
            _c("iframe", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.loading,
                  expression: "loading",
                  modifiers: { fullscreen: true, lock: true },
                },
              ],
              staticClass: "frame",
              attrs: {
                id: "gameFrameparent",
                "element-loading-text": "正在加载，请稍后",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
                src: _vm.url,
                width: "100%",
                height: "100%",
              },
              on: {
                load: function ($event) {
                  _vm.loading = false
                },
              },
            }),
            _c(
              "div",
              {
                ref: "eventdetail",
                staticClass: "btn",
                on: {
                  mousedown: function ($event) {
                    return _vm.mouseDownHandleelse($event)
                  },
                  mouseup: function ($event) {
                    return _vm.mouseUpHandleelse($event)
                  },
                },
              },
              [
                _c(
                  "i",
                  { staticClass: "cp-icon", attrs: { title: "返回导览页" } },
                  [_vm._v("")]
                ),
              ]
            ),
          ]
        : [
            !_vm.levelFlag
              ? _c("div", { staticClass: "cp-preview__bigbg" }, [
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: _vm.centerLogo.logoUrl },
                  }),
                  _vm.isFirstShow
                    ? _c("div", { staticClass: "first-nav" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.firstList, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.levelOneClick(item)
                                  },
                                },
                              },
                              [
                                _c("a", { attrs: { href: "javascript:;" } }, [
                                  _c("img", {
                                    staticClass: "ued-datav",
                                    attrs: { src: item.iconUrl },
                                  }),
                                  _c("p", [
                                    _vm._v(
                                      " " + _vm._s(item.name.substr(0, 5))
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm.isSeconedShow
                    ? _c("div", { staticClass: "seconde-nav" }, [
                        _c(
                          "ul",
                          [
                            _c("div", { staticStyle: { display: "none" } }, [
                              _vm._v(
                                _vm._s(
                                  (_vm.down = Math.ceil(
                                    _vm.sconedData.length / 2
                                  ))
                                ) +
                                  " " +
                                  _vm._s((_vm.up = 1))
                              ),
                            ]),
                            _vm._l(_vm.sconedData, function (item) {
                              return [
                                _vm.down >= 1
                                  ? _c(
                                      "li",
                                      {
                                        key: item.id,
                                        class: "item" + (_vm.down * 2 - 1),
                                        on: {
                                          click: function ($event) {
                                            return _vm.levelTwoClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          { attrs: { href: "javascript:;" } },
                                          [
                                            _c("img", {
                                              staticClass: "ued-datav",
                                              attrs: { src: item.iconUrl },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "padding-top": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.name.substr(0, 5))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { display: "none" } },
                                          [_vm._v(_vm._s(_vm.down--))]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "li",
                                      {
                                        key: item.id,
                                        class: "item" + _vm.up * 2,
                                        on: {
                                          click: function ($event) {
                                            return _vm.levelTwoClick(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          { attrs: { href: "javascript:;" } },
                                          [
                                            _c("img", {
                                              staticClass: "ued-datav",
                                              attrs: { src: item.iconUrl },
                                            }),
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "padding-top": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.name.substr(0, 5))
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { display: "none" } },
                                          [_vm._v(_vm._s(_vm.up++))]
                                        ),
                                      ]
                                    ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.isThreeShow
                    ? _c("div", { staticClass: "three-nav" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.threeData, function (item) {
                            return _c(
                              "li",
                              {
                                key: item.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.goUrl(
                                      item.thirdUrl,
                                      item.newWindowFlag
                                    )
                                  },
                                },
                              },
                              [
                                _c("a", { attrs: { href: "javascript:;" } }, [
                                  _c("img", {
                                    staticClass: "ued-datav",
                                    attrs: { src: item.iconUrl },
                                  }),
                                  _c("p", [_vm._v(_vm._s(item.name))]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  !_vm.isFirstShow
                    ? _c(
                        "div",
                        {
                          staticClass: "goback",
                          on: { click: _vm.toLevelOne },
                        },
                        [
                          _c("i", { staticClass: "ued-datav cp-icon" }, [
                            _vm._v(""),
                          ]),
                          _c("label", [_vm._v("返回")]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "cp-preview__all" }, [
                  _c("img", {
                    staticClass: "logo",
                    attrs: {
                      src: require("@/assets/images/xianhai/title.png"),
                    },
                  }),
                  _c(
                    "ul",
                    _vm._l(_vm.levelList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          class: "item" + index,
                          style: {
                            background:
                              index >= 30 ? _vm.randomBackground(index) : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(
                                item.thirdUrl,
                                item.newWindowFlag
                              )
                            },
                          },
                        },
                        [
                          _c("a", [
                            _c("img", {
                              staticClass: "ued-datav",
                              attrs: { src: item.iconUrl },
                            }),
                            _c("p", [
                              _vm._v(" " + _vm._s(item.name.substr(0, 5))),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }