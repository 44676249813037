<template>
  <section class="menu-child-box">
    <div
      v-for="(item, index) in childrenCopy"
      :key="item.name"
      class="icons menu-scd"
      :class="'icon' + index"
      :style="{visibility: !shootRuning ? 'visible' : 'hidden'}"
      @click="goUrl(item.thirdUrl)">
      <p class="name"
         :class="initAnimotionClass(index)">
        {{ item.name }}
      </p>
      <img
        v-if="children.length % 2 === 0 && index === 0"
        style="visibility: hidden"
        src="../../../assets/images/wgs/light.png">
      <img v-else
           :class="initAnimotionClass(index)"
           :src="item.iconUrl">
    </div>
  </section>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeMenuChild',
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    shootRuning: {
      type: Boolean,
      default() {
        return false;
      }
    }

  },
  data() {
    return {
      showFloatAnimotion: false,
      paths: [],
      children: [

      ]
    };
  },
  computed: {
    childrenCopy () {
      if (this.children.length % 2 === 0 && this.children.length > 0) {
        return [{ name: '' }, ...this.children];
      } else {
        return this.children;
      }
    }
  },
  mounted() {
    this.initPaths();
    // this.playAnime();
  },
  methods: {
    initAnimotionClass(index) {
      if (Math.ceil(index / 2) % 2 === 0) {
        return { 'float-animotion-up-start': this.showFloatAnimotion };
      } else {
        return { 'float-animotion-down-start': this.showFloatAnimotion };
      }
    },
    resetData(data = []) {
      this.children = data;
      this.showFloatAnimotion = false;
    },
    initPaths() {
      for (let i = 1; i < 7; i++) {
        this.paths.push(anime.path(`.path${ i } path`));
      }
    },
    clearAnime() {
      anime.remove('.menu-scd');
    },
    playAnime() {
      const a = anime({
        targets: '.menu-child-box .icons',
        opacity: 1,
        autoplay: false,
        easing: 'easeInOutQuint',
        translateX: (el, i) => {
          if (i === 0) {
            return 0;
          } else {
            return this.paths[i - 1]('x');
          }
        },
        translateY: (el, i) => {
          if (i === 0) {
            return 0;
          } else {
            return this.paths[i - 1]('y');
          }
        },
        complete: () => {
          this.showFloatAnimotion = true;
          // this.playFloat();
        },
        duration: 2000
      });
      a.seek(700);
      a.play();
    },
    // playFloat() {
    //   anime({
    //     targets: '.menu-child-box .icons',
    //     translateY (el, i) {
    //       if (Math.ceil(i / 2) % 2 === 0) {
    //         return '-=5';
    //       } else {
    //         return '+=5';
    //       }
    //     },
    //     duration: 1000,
    //     easing: 'linear',
    //     direction: 'alternate',
    //     loop: true
    //   });
    // },
    // 打开新窗口页面
    goUrl(url) {
      if (url) {
        window.open(url);
      }
    }
  }

};
</script>

<style scoped lang="scss">
@import "./wgs-guide.scss";

.icons {
      position: absolute;
      top: -1.15vw;
      left: -1.15vw;
      width: 2.3vw;
      height: 2.3vw;
      cursor: pointer;
      z-index: 9;

      .name{
        color: #BDD3FF;
        font-size: 16px;
        top: -30px;
        width: 100%;
        text-align: center;
        position: absolute;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &.icon0 {
        bottom: 0;
        top: auto;
        left: 0;
        right: 0;
        margin: auto;
      }
      &.icon1 {
        top: -1.45vw;
      }
      &.icon2 {
        top: -1.45vw;
      }
    }
</style>
