/* Automatically generated by './build/update-component-index-file.js' */
import Header from "./header";
import Sidebar from "./sidebar";
import JmgGuide from "./jmg-guide";
import WushanGuide from "./wushan-guide";
import WgsGuide from "./wgs-guide";
import WgsGuideSecond from "./wgs-guide-second";
import WeatherDialog from "./weather-dialog";
import XhGuide from "./xianhai-guide";
import SbyGuide from "./sby-guide";

const components = [
  Header,
  Sidebar,
  JmgGuide,
  WushanGuide,
  WgsGuide,
  WgsGuideSecond,
  WeatherDialog,
  XhGuide,
  SbyGuide
];

const install = function install(Vue) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  version: "1.0.0",
  install,
  Header,
  Sidebar,
  JmgGuide,
  WushanGuide,
  WgsGuide,
  WeatherDialog
};
