const getters = {
  token: state => state.token,
  authCode: state => state.authCode,
  user: state => state.user,
  systemId: state => state.systemId,
  systemUrl: state => state.systemUrl,
  siteId: state => state.siteId,
  system: state => state.system,
  messageStatic: state => state.messageStatic,
  messageDeatil: state => state.messageDeatil,
  refreshNum: state => state.refreshNum,
  showMessage: state => state.showMessage,
  isThridSys: state => state.isThridSys
};

export default getters;
