var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "anime-bg-box" }, [
      _c("div", { staticClass: "bg-down" }),
      _c("div", { staticClass: "bg-up" }),
      _c("img", {
        staticClass: "bg-icon4",
        attrs: { src: require("../../../assets/images/wgs/bg-icon4.png") },
      }),
      _c("img", {
        staticClass: "bg-icon1",
        attrs: { src: require("../../../assets/images/wgs/bg-icon1.png") },
      }),
      _c("img", {
        staticClass: "bg-icon2",
        attrs: { src: require("../../../assets/images/wgs/bg-icon2.png") },
      }),
      _c("img", {
        staticClass: "bg-icon3",
        attrs: { src: require("../../../assets/images/wgs/bg-icon3.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }