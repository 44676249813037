<template>
  <section class="light-circle-box">
    <img class="ligth ligth1"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth2"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth3"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth4"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth5"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth6"
         src="../../../assets/images/wgs/light.png">
    <img class="ligth ligth7"
         src="../../../assets/images/wgs/light.png">
  </section>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeLightCircle',
  data() {
    return {};
  },
  mounted() {
    anime({
      targets: '.light-circle-box .ligth',
      opacity: 0,
      duration: 1000,
      scale: 0,
      loop: true,
      autoplay: true,
      easing: 'linear',
      direction: 'alternate'
      // delay:anime.stagger([0,2000])
    });
  }
};
</script>

<style scoped  lang="scss">
.light-circle-box{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  .ligth{
    position: absolute;
    width: 1.5vw;
    height: 1.5vw;
  }
  .ligth1{
    right: 0;
    top: 8.8vw;
  }
  .ligth2{
    left: 0;
    top: 8.8vw;
  }
  .ligth3{
    left: 40vw;
    top: 12.35vw;
  }
  .ligth4{
    left: 22vw;
    top: 9.55vw;
  }
  .ligth5{
    left: 43vw;
    top: 9.55vw;
  }
  .ligth6{
    left: 35vw;
    top: 7.65vw;
  }
  .ligth7{
    left: 16.6vw;
    top: 5.45vw;
  }
}
</style>
