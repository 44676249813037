var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "cp-preview",
    },
    [
      [
        !_vm.isBeThree
          ? _c("div", { staticClass: "cp-preview__header" }, [
              _c("div", { staticClass: "cp-preview__header_left" }),
              _c("div", { staticClass: "cp-preview__header_center" }, [
                _c("img", {
                  staticStyle: { "max-height": "88px" },
                  attrs: { src: _vm.centerLogo.logoUrl },
                }),
              ]),
              _c("div", { staticClass: "cp-preview__header_right" }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "cp-preview__bigbg" }, [
          _c(
            "div",
            { staticClass: "page-login--layer page-login--layer-area" },
            [
              _c("div", { staticClass: "star" }),
              _c("div", { staticClass: "star pink" }),
              _c("div", { staticClass: "star blue" }),
              _c(
                "ul",
                { staticClass: "circles" },
                _vm._l(30, function (n) {
                  return _c("li", { key: n })
                }),
                0
              ),
              _c("div", { staticClass: "circles-box" }),
            ]
          ),
          _c("div", { staticClass: "guide-center" }, [
            _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
              ? _c("div", {
                  staticClass: "guide-center-bottom",
                  on: {
                    click: function ($event) {
                      return _vm.goUrl(
                        _vm.centerUrlInfo.thirdUrl,
                        _vm.centerUrlInfo.newWindowFlag
                      )
                    },
                  },
                })
              : _vm._e(),
            _vm.centerUrlInfo && _vm.centerUrlInfo.iconUrl
              ? _c("div", { staticClass: "guide-center-guang" })
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.shownum,
                  expression: "!shownum",
                },
              ],
              staticClass: "rotation3D",
              attrs: { id: "rotation3D" },
            },
            [
              _c(
                "div",
                { staticClass: "itemList" },
                _vm._l(_vm.levelList, function (item, index) {
                  return _c(
                    "div",
                    { key: `list-` + index, staticClass: "rotation3D__item" },
                    [
                      _c("div", { staticClass: "scale" }, [
                        _c("div", {
                          staticClass: "baseImg",
                          style: `background-image: url(${item.iconUrl})`,
                        }),
                        _c("div", { staticClass: "cont" }, [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "white-space": "nowrap",
                                overflow: "hidden",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    item.thirdUrl,
                                    item.newWindowFlag
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "main-body" },
          _vm._l(36, function (i) {
            return _c("span", {
              key: `card${i}`,
              class:
                Math.random() < 0.3
                  ? "animate1"
                  : Math.random() > 0.6
                  ? "animate2"
                  : "animate3",
              style: { opacity: Math.random() },
            })
          }),
          0
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }