<template>
  <div class="menu-first">
    <div
      v-for="(item, index) in levelListCopy"
      :key="index"
      class="menu-first-icons"
      :class="'icon' + index"
      :style="{'transform': isExpand || (item.active) ? 'scale(1)' : 'scale(0.8)'}">
      <div v-if="levelList.length % 2 === 0 && index === 0">
      </div>
      <div v-else
           class="item"
           @click="firstIconClick(item,index,$event)">
        <img
          class="child"
          :class="initAnimotionClass(index)"
          :src="item.iconUrl">
        <span class="name"
              :class="initAnimotionClass(index)"
              :style="{'color': item.active ? '#fff' : '#ecf7ff'}">{{ item.name }}</span>
        <div v-show="item.active"
             class="circles">
          <span class="circle circle1"></span>
          <span class="circle circle2"></span>
          <span class="circle circle3"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeMenuFirst',
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // menus: [{ active: false }, { active: false }, { active: false }, { active: false }, { active: false }, { active: false }, { active: false }],
      isExpand: true,
      floatAnime: null,
      showFloatAnimotion: false
    };
  },
  computed: {
    levelListCopy () {
      if (this.levelList.length % 2 === 0 && this.levelList.length > 0) {
        return [{ name: '' }, ...this.levelList];
      } else {
        return this.levelList;
      }
    }
  },
  // watch: {
  //   levelList: {
  //     handler(val) {
  //       if (val && val.length > 0) {
  //         console.log(val);
  //       }
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.playAnimie();
      }, 1000);
    });
  },
  methods: {
    initAnimotionClass(index) {
      if (Math.ceil(index / 2) % 2 === 0) {
        return { 'float-animotion-up-start': this.showFloatAnimotion };
      } else {
        return { 'float-animotion-down-start': this.showFloatAnimotion };
      }
    },
    firstIconClick(item, index) {
      this.levelList.forEach((im) => {
        im.active = false;
      });
      item.active = true;
      // 一级图标收缩
      if (this.isExpand) {
        anime.remove('.menu-first .menu-first-icons .item');
        this.unExpand(index);
      }
      this.$emit('firstIconClick', index);
      this.$forceUpdate();
    },
    playAnimie() {
      this.showFloatAnimotion = true;
      // this.floatAnime = anime({
      //   targets: '.menu-first .menu-first-icons .item',
      //   translateY: (el, i) => {
      //     if (Math.ceil(i / 2) % 2 === 0) {
      //       return '-=5';
      //     } else {
      //       return '+=5';
      //     }
      //   },
      //   duration: 1000,
      //   easing: 'linear',
      //   direction: 'alternate',
      //   loop: true
      // });
    },
    unExpand() {
      this.isExpand = false;
      anime({
        targets: '.menu-first .menu-first-icons .item',
        translateY: '-=45',
        translateX (el, i) {
          if (i === 0) {
            return '+=0';
          } else if (i % 2 === 0 && i !== 6) {
            return '+=20';
          } else if (i === 5) {
            return '-=60';
          } else if (i === 6) {
            return '+=60';
          } else {
            return '-=20';
          }
        },
        duration: 300,
        easing: 'linear',
        complete: () => {
          this.playAnimie();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "./wgs-guide.scss";
@keyframes move1 {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}
.menu-first {
  height: 100%;
  width: 60%;
  position: absolute;
  left: 20%;
  bottom: 27%;
  z-index: 9;

  height: 100%;
  width: 60%;
  position: absolute;
  left: 20%;
  bottom: 27%;
  .menu-first-icons {
    width: 4.271vw;
    position: absolute;
    cursor: pointer;
    z-index: 9;
    transition: all 0.3s;
    .item {
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      position: relative;

      .name {
        color: #ecf7ff;
        bottom: -27px;
        width: 100%;
        position: absolute;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .circles {
        position: absolute;
        width: 4.271vw;
        height: 4.271vw;
        top: 0;
        left: 0;
        z-index: 1;
        .circle {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid #BDD3FF;
          border-radius: 50%;
          box-sizing: border-box;
          transform: scale(0.7);
        }
        .circle1 {
          animation: move1 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        .circle2 {
          animation: move1 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-delay: 0.2s;
        }
        .circle3 {
          animation: move1 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-delay: 0.4s;
        }
      }
    }
    .child {
      width: 4.271vw;
      cursor: pointer;
      z-index: 9;
      position: relative;
    }
    &.icon0 {
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    &.icon1 {
      bottom: 2%;
      right: 27%;
    }
    &.icon2 {
      bottom: 2%;
      left: 27%;
    }
    &.icon3 {
      bottom: 8.5%;
      right: 10%;
    }
    &.icon4 {
      bottom: 8.5%;
      left: 10%;
    }
    &.icon5 {
      bottom: 30%;
      right: -5%;
    }
    &.icon6 {
      bottom: 30%;
      left: -5%;
    }
  }
}
</style>
