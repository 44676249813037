/*
 * @Descripttion: Vuex入口配置js，集成各个模块并导出vuex实例
 * @Author: koujing
 * @Date: 2021-02-26 14:43:28
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-01 17:19:22
 */
import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/config';
// 引入vuex数据持久化插件
import createPersistedState from 'vuex-persistedstate';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
// 引入子模块儿[用于需存储状态较多时的情况]
import system from './modules/system';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    system
  },
  state,
  getters,
  actions,
  mutations,
  // 开发环境下使用严格模式
  strict: process.env.NODE_ENV === 'development',
  // vuex持久化配置，并存储命名
  plugins: [createPersistedState({
    key: config.storageName
  })]
});
