<template>
  <div style="width:100%;height:100%;position: absolute;left: 0;top: 0;z-index: 0;">
    <svg class="shoot"
         viewBox="0 0 99.7 23.6">
      <path class="shoot0"
            fill="none"
            stroke=""
            d="M50.35 12.247, L50.5 23.6"></path>
      <path class="shoot1"
            fill="none"
            stroke=""
            d="M60 13, L50.5 23.6"></path>
      <path class="shoot2"
            fill="none"
            stroke=""
            d="M40.5 13, L50.5 23.6"></path>
      <path class="shoot3"
            fill="none"
            stroke=""
            d="M70.5 11, L50.5 23.6"></path>
      <path class="shoot4"
            fill="none"
            stroke=""
            d="M31 11.5, L50.5 23.6"></path>
      <path class="shoot5"
            fill="none"
            stroke=""
            d="M75 8, L50.5 23.6"></path>
      <path class="shoot6"
            fill="none"
            stroke=""
            d="M24.5 6.5, L50.5 23.6"></path>


      <!-- <path class="shoot1" fill="none" stroke="" d="M60 11.247, L49.85 22.5" />
      <path class="shoot2" fill="none" stroke="" d="M40.5 11.247, L49.85 22.5" />
      <path class="shoot3" fill="none" stroke="" d="M71 8.8, Q62 18,49.85 22.5" />
      <path class="shoot4" fill="none" stroke="" d="M30 9.3, Q35 18,49.85 22.5" />
      <path class="shoot5" fill="none" stroke="" d="M75 4.3, Q70 20,49.85 22.5" />
      <path class="shoot6" fill="none" stroke="" d="M24.5 3.3, Q25 20,49.85 22.5" /> -->
    </svg>
    <svg class="planet"
         viewBox="0 0 99.7 23.6">
      <path class="planet-path1"
            fill="none"
            stroke=""
            d="M41.55  0,A42.9 10   0 1 1 41.55 19.6, M41.55  19.6, A43.4 10   0 0 1 41.55 0 "></path>
      <path class="planet-path2"
            fill="none"
            stroke=""
            d="M43.45  0,A49.3 11.9 0 1 1 43.45 23.6, M43.45  23.6, A49.3 11.9 0 0 1 43.45 0"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AnimeSvgShoot'
};
</script>

<style scoped lang="scss">
svg{
  position: absolute;
  left: 0;
}
</style>
