<template>
  <div class="cp-preview">
    <div class="cp-preview__header"
         :style="`background: url(${centerLogo.logoUrl}) no-repeat top center;`">
    </div>
    <div class="cp-preview__semicircle"></div>
    <div class="cp-preview__semicircle-right"></div>
    <div class="cp-preview__bigbg">
      <div class="page-login--layer page-login--layer-area">
        <ul class="circles">
          <li v-for="n in 40"
              :key="n"></li>
        </ul>
      </div>
      <div class="guide-center">
        <div v-if="centerUrlInfo && centerUrlInfo.iconUrl"
             class="guide-center-bottom"
             :style="`background-image:url(${centerUrlInfo.iconUrl})`"
             @click="goUrl(centerUrlInfo.thirdUrl)"></div>
        <div v-if="centerUrlInfo && centerUrlInfo.iconUrl"
             class="guide-center-guang"></div>
      </div>
      <div v-show="!shownum"
           id="rotation3D"
           class="rotation3D">
        <button class="center">
          中心
        </button>
        <div class="itemList">
          <div v-for="(item, index) in levelList"
               :key="`list-` + index"
               class="rotation3D__item">
            <div class="scale">
              <div v-if="item.childList"
                   class="small-bottom-box">
                <div v-for="(btitem, bindex) in item.childList"
                     :key="`s_${bindex}`"
                     class="small-bottom-info"
                     @click="goUrl(btitem.thirdUrl)">
                  <div class="bottom-big-sc">
                    <div class="bottom-small-sc">
                      <img v-if="btitem.iconUrl"
                           :src="btitem.iconUrl"
                           :alt="btitem.name">
                      <i v-else
                         class="cp-icon">&#xeb1d;</i>
                    </div>
                  </div>
                  <div class="small-bottom-txt">
                    {{ btitem.name }}
                  </div>
                </div>
              </div>
              <div class="baseImg"
                   :style="`background-image: url(${item.iconUrl})`"></div>
              <div class="cont">
                <i class="iconfont"
                   :class="item.icon"></i>
                <p @click="goUrl(item.thirdUrl)">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-body">
      <span v-for="i in 36"
            :key="`card${i}`"
            :class="Math.random() < 0.3 ? 'animate1' : Math.random() > 0.6 ? 'animate2' : 'animate3'"
            :style="{opacity: Math.random()}">
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WushanGuide',
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    centerInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    centerLogo: {
      type: Object,
      default() {
        return {};
      }
    },
    screenWidth: {
      type: String,
      default: ''
    },
    screenHeight: {
      type: String,
      default: ''
    },
    isshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 滚动对象实例
      rotation3D: null,
      shownum: false,
      centerUrlInfo: {}
    };
  },
  watch: {
    levelList: {
      handler(val) {
        if (val && val.length > 0) {
          this.showAnimation();
        }
      },
      deep: true,
      immediate: true
    },
    centerInfo: {
      handler(val) {
        console.log(val);
        this.centerUrlInfo = val;
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.rotation3D) {
      this.rotation3D.close();
      this.rotation3D = null;
    }
  },
  mounted() {
    this.showAnimation();
  },
  methods: {
    // 加载动画
    showAnimation() {
      console.log(this.shownum);
      if (this.shownum) {
        return;
      }
      this.shownum = true;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.rotation3D) {
            this.rotation3D.close();
            this.rotation3D = null;
          }
          // eslint-disable-next-line no-undef
          this.rotation3D = new Rotation3D({
            id: '#rotation3D',
            farScale: 0.4,
            // farScale: 1,
            // x半径压缩
            xRadius: 0,
            // y半径压缩
            yRadius: 260,
            // yRadius: 0,  //y半径压缩
            autoPlay: true,
            autoPlayDelay: 6000
          });
          this.shownum = false;
          this.rotation3D.goTo(2);
        }, 1000);
      });
    },
    // 打开新窗口页面
    goUrl(url) {
      if (url) {
        window.open(url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./wushan-guide.scss";
</style>
