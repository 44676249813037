/* istanbul ignore next */
export default { v: '5.5.6',
  fr: 25,
  ip: 0,
  op: 400,
  w: 3840,
  h: 1080,
  nm: '预合成 3',
  ddd: 0,
  assets: [{ id: 'image_0',
    w: 54,
    h: 222,
    u: 'images/',
    p: 'img_1.png',
    e: 0 }],
  layers: [{ ddd: 0,
    ind: 1,
    ty: 2,
    nm: '光1',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 25,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 40,
      s: [80] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 49,
      s: [80] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 75,
      s: [0] }, { t: 80,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 25,
      s: [1535, 610, 0],
      to: [0, -19.826, 0],
      ti: [0, 55.84, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 75,
      s: [1535, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 80,
        s: [1535, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [50, 50, 100],
      ix: 6 } },
    ao: 0,
    ip: 25,
    op: 430,
    st: 25,
    bm: 0 }, { ddd: 0,
    ind: 2,
    ty: 2,
    nm: '光2',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 0,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 15,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 24,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 50,
      s: [0] }, { t: 55,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 0,
      s: [1635, 610, 0],
      to: [0, -10.773, 0],
      ti: [0, 25.499, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 50,
      s: [1635, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 55,
        s: [1635, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [90, 90, 100],
      ix: 6 } },
    ao: 0,
    ip: 0,
    op: 405,
    st: 0,
    bm: 0 }, { ddd: 0,
    ind: 3,
    ty: 2,
    nm: '光3',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 13,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 28,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 37,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 63,
      s: [0] }, { t: 68,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 13,
      s: [1735, 648, 0],
      to: [0, -19.826, 0],
      ti: [0, 55.84, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 63,
      s: [1735, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 68,
        s: [1735, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [80, 80, 100],
      ix: 6 } },
    ao: 0,
    ip: 13,
    op: 418,
    st: 13,
    bm: 0 }, { ddd: 0,
    ind: 4,
    ty: 2,
    nm: '光4',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 13,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 28,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 37,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 63,
      s: [0] }, { t: 68,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 13,
      s: [2090, 610, 0],
      to: [0, -75.667, 0],
      ti: [0, 75.667, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 63,
      s: [2090, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 68,
        s: [2090, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [100, 100, 100],
      ix: 6 } },
    ao: 0,
    ip: 13,
    op: 418,
    st: 13,
    bm: 0 }, { ddd: 0,
    ind: 5,
    ty: 2,
    nm: '光5',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 3,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 18,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 27,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 53,
      s: [0] }, { t: 58,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 3,
      s: [2200, 620, 0],
      to: [0, -19.826, 0],
      ti: [0, 55.84, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 53,
      s: [2200, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 58,
        s: [2200, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [90, 90, 100],
      ix: 6 } },
    ao: 0,
    ip: 3,
    op: 408,
    st: 3,
    bm: 0 }, { ddd: 0,
    ind: 6,
    ty: 2,
    nm: '光6',
    refId: 'image_0',
    sr: 1,
    ks: { o: { a: 1,
      k: [{ i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 25,
      s: [0] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 40,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 49,
      s: [100] }, { i: { x: [0.667],
        y: [1] },
      o: { x: [0.333],
        y: [0] },
      t: 75,
      s: [0] }, { t: 80,
        s: [0] }],
      ix: 11,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    r: { a: 0,
      k: 0,
      ix: 10 },
    p: { a: 1,
      k: [{ i: { x: 0.667,
        y: 1 },
      o: { x: 0.333,
        y: 0 },
      t: 25,
      s: [2303, 610, 0],
      to: [0, -75.667, 0],
      ti: [0, 75.667, 0] }, { i: { x: 0.667,
        y: 0.667 },
      o: { x: 0.333,
        y: 0.333 },
      t: 75,
      s: [2303, 156, 0],
      to: [0, 0, 0],
      ti: [0, 0, 0] }, { t: 80,
        s: [2303, 156, 0] }],
      ix: 2,
      x: 'var $bm_rt;\n$bm_rt = loopOutDuration(\'cycle\', 0);' },
    a: { a: 0,
      k: [27, 111, 0],
      ix: 1 },
    s: { a: 0,
      k: [70, 70, 100],
      ix: 6 } },
    ao: 0,
    ip: 25,
    op: 430,
    st: 25,
    bm: 0 }],
  markers: [] };
