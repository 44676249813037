<template>
  <transition name="fade">
    <div
      v-if="show"
      class="cp-weather-dialog">
      <div class="cp-weather-dialog__wrapper">
        <div class="cp-weather-dialog__header">
          <div class="title">
            景区气象数据
          </div>
          <span
            class="close cp-icon"
            @click="hide">
            &#xea17;
          </span>
        </div>
        <div class="cp-weather-dialog__body">
          <div class="info">
            <div
              v-if="data.hourly_forecast && data.daily_forecast && data.aqi"
              class="left">
              <p class="current-tmp">
                {{ data.hourly_forecast[0].tmp }}<span>℃</span>
              </p>
              <p class="tmp">
                <span class="status">
                  {{ data.daily_forecast[0].cond.txt_d }}
                </span>
                {{ data.daily_forecast[0].tmp.min }}℃-
                {{ data.daily_forecast[0].tmp.max }}℃
              </p>
              <p>
                <span class="date">
                  {{ data.daily_forecast[0].date }}
                </span>
                <span class="week">
                  {{ data.daily_forecast[0].week }} {{ getDate() }}
                </span>
              </p>
            </div>
            <div
              v-if="data.aqi"
              class="right">
              <p>
                <span class="aqi">{{ data.aqi.aqi }}</span>
                <span class="qlty">{{ data.aqi.qlty }}</span>
              </p>
              <ul>
                <li>
                  <span class="value no-display">0</span>
                  <span class="name">空气质量</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.pm25 }}</span>
                  <span class="name">PM2.5</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.pm10 }}</span>
                  <span class="name">PM10</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.so2 }}</span>
                  <span class="name">SO2</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.no2 }}</span>
                  <span class="name">NO2</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.o3 }}</span>
                  <span class="name">O3</span>
                </li>
                <li>
                  <span class="value">{{ data.aqi.co }}</span>
                  <span class="name">CO</span>
                </li>
              </ul>
            </div>
          </div>
          <ul
            v-if="data.daily_forecast"
            class="list">
            <li
              v-for="item in data.daily_forecast"
              :key="item.date"
              class="item">
              <span class="week">{{ item.week }}</span>
              <span class="date">{{ item.date }}</span>
              <span
                class="daq-weather"
                v-html="item.cond.unicode_d">
              </span>
              <span class="level">{{ item.wind.dir }}&lt;{{ item.wind.spd }}级</span>
            </li>
          </ul>
          <div
            ref="canvas"
            class="echarts">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import calendar from '@/utils/calendar';
export default {
  name: 'CpWeatherDialog',
  props: {
    show: Boolean,
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chart: null,
      tooltipLooper: null,
      options: {
        tooltip: {
          show: false
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '20%',
          containLabel: false
        },
        xAxis: [
          {
            show: false,
            type: 'category',
            data: [
              '制造业',
              '建筑业',
              '农林牧渔',
              '房地产',
              '金融业',
              '居民服务及其他',
              '居民服务及其他1'
            ],
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0.12)'
              }
            },
            axisLabel: {
              margin: 10,
              color: '#5c8db0',
              rotate: 40,
              textStyle: {
                fontSize: 14
              }
            }
          }
        ],
        yAxis: [
          {
            show: false,
            name: '单位：件',
            axisLabel: {
              formatter: '{value}',
              color: '#5c8db0'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.12)'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#2e474c'
              }
            }
          }
        ],
        series: []
      }
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          const dailyForecast = this.data.daily_forecast;
          if (dailyForecast && dailyForecast.length) {
            const minTmp = [];
            const maxTmp = [];
            for (let i = 0; i < dailyForecast.length; i++) {
              minTmp.push(dailyForecast[i].tmp.min);
              maxTmp.push(dailyForecast[i].tmp.max);
            }
            this.options.series = [
              {
                type: 'line',
                data: minTmp,
                barWidth: '1px',
                symbol: 'emptyCircle',
                smooth: true,
                symbolSize: 10,
                label: {
                  normal: {
                    show: true,
                    /* eslint object-shorthand: 0 */
                    formatter: function (param) {
                      return `${ param.value }℃`;
                    },
                    color: '#fff'
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#ffffff'
                  }
                }
              },
              {
                type: 'line',
                data: maxTmp,
                barWidth: '1px',
                symbol: 'emptyCircle',
                smooth: true,
                symbolSize: 10,
                label: {
                  normal: {
                    show: true,
                    /* eslint object-shorthand: 0 */
                    formatter: function (param) {
                      return `${ param.value }℃`;
                    },
                    color: '#fff'
                  }
                },
                itemStyle: {
                  normal: {
                    color: '#fff'
                  }
                }
              }
            ];
          }
          this.chart = this.$echarts.init(this.$refs.canvas);
          this.chart.setOption(this.options);
        });
      }
    }
  },
  methods: {
    hide() {
      this.$parent.show = false;
    },
    // 获取农历事件
    getDate() {
      const time = new Date();
      const date = new Intl.DateTimeFormat('zh-u-ca-chinese', { dateStyle: 'long' }).format(time);
      if (date.includes('年')) {
        return `农历${ date.split('年')[1] }`;
      } else {
        const obj = calendar.solar2lunar();
        return `农历${ obj.IMonthCn }${ obj.IDayCn }`;
      }
    }
  }
};
</script>

<style lang="scss">
// @import "~@/scss/mixins/mixins.scss";

@font-face {
  font-family: 'daq';  /* project id 705095 */
  src: url('//at.alicdn.com/t/font_705095_6c18erjtii3.eot');
  src: url('//at.alicdn.com/t/font_705095_6c18erjtii3.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.woff') format('woff'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_705095_6c18erjtii3.svg#daq') format('svg');
}
.daq-weather{
  font-family:'daq'!important;
  font-size:20px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@include b(weather-dialog){
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left:0;
  top:0;
  bottom:0;
  right: 0;
  z-index:100;
  background: rgba(0,0,0,.5);
  @include e(wrapper){
    width: 904px;
    border-radius: 5px;
    text-align: left;
    background-image: linear-gradient(#69541f,#6a665b);
  }
  @include e(header){
    display: flex;
    align-items: center;
    height: 58px;
    padding:0 15px;
    line-height: 48px;
    border-bottom:1px solid rgba(255,255,255,.1);
    .title{
      font-size:16px;
      font-weight: bold;
      color:#fff;
      flex:1;
    }
    .close{
      color:#fff;
      opacity: 0.8;
      cursor: pointer;
      transition: all .25s ease 0s;
      &:hover{
        opacity: 1;
      }
    }
  }
  @include e(body){
    position: relative;
    padding:20px;
    color:#fff;
    .info{
      display: flex;
      font-size:16px;
      .aqi,
      .current-tmp{
        height: 69px;
        line-height: 69px;
        font-size: 56px;
        span{
          padding-left:10px;
          font-size:20px;
        }
      }
      .tmp {
        margin-bottom: 5px;
      }
      .qlty{
        padding:0 10px;
      }
      ul{
        display: flex;
        justify-content: space-between;
      }
      li{
        .value{
          margin-bottom: 5px;
          display: block;
          &.no-display {
            color: transparent;
          }
        }
        .name{
          color:#fff;
        }
      }
      .left{
        flex:5;
      }
      .right{
        flex:6;
      }
    }
    .list{
      height: 287px;
      margin-top:20px;
      display: flex;
      border:1px solid rgba(255,255,255,.2);
    }
    .item{
      flex:1;
      height: 285px;
      padding-top:10px;
      border-right:1px solid rgba(255,255,255,.2);
      &:last-child{
        border:none;
      }
      &:first-child{
        background: rgba(255,255,255,.2);
      }
      span{
        display: block;
        text-align: center;
      }
      .date{
        padding:3px 0;
        color:#d8dde5;
      }
      .level{
        padding-top:150px;
      }
    }
    .echarts{
      position: absolute;
      left:20px;
      bottom:60px;
      right: 20px;
      height: 150px;
    }
  }
}
</style>
