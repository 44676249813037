<template>
  <section class="particle-box">
    <span
      v-for="n in count"
      :key="n"
      class="particle"
      :class="'particle' + n"></span>
  </section>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeParticle',
  data() {
    return {
      count: 70,
      points: []
    };
  },
  created() {
    this.genaratePoints();
  },
  mounted() {
    this.playParticle();
  },
  methods: {
    genaratePoints() {
      for (let i = 0; i < this.count; i++) {
        const t = { x: '',
                    y: '' };
        const index = Math.floor(Math.random() * 12) % 4;
        if (index === 0) {
          t.y = '50vh';
          const p = Math.random() < 0.5 ? -1 : 1;
          t.x = `${ Math.random() * 70 * p }vw`;
        } else if (index === 1) {
          t.y = '-50vh';
          const p = Math.random() < 0.5 ? -1 : 1;
          t.x = `${ Math.random() * 70 * p }vw`;
        } else if (index === 2) {
          t.x = '-50vw';
          const p = Math.random() < 0.5 ? -1 : 1;
          t.y = `${ Math.random() * 70 * p }vh`;
        } else if (index === 3) {
          t.x = '50vw';
          const p = Math.random() < 0.5 ? -1 : 1;
          t.y = `${ Math.random() * 70 * p }vh`;
        }
        this.points.push(t);
      }
    },
    playParticle() {
      for (let i = 1; i < this.count; i++) {
        anime({
          targets: `.particle-box .particle${ i }`,
          translateX: () => this.points[i].x,
          opacity: [0.3, 0.6],
          scale: [0.5, 1.2],
          translateY: () => this.points[i].y,
          loop: true,
          duration: 12000,
          easing: 'linear',
          delay: i * 400
        });
      }
    }
  }
};
</script>

<style scoped  lang="scss">
.particle-box {
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .particle,
  .particle-delay {
    display: inline-block;
    width: 0.2vw;
    height: 0.2vw;
    background: #4de1ff;
    border-radius: 100%;
    box-shadow: 0 0 2px 0px #bdd3ff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
  }
}
</style>
