/**
 * xx模块路由
 */
const router = [
  {
    path: '/error/:errorCode',
    name: 'error',
    meta: {
      title: '发生了错误'
    },
    component: () => import('@/views/error/index.vue')
  }
];

export default router;
