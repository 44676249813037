<!--
 * @Descripttion: 页面入口文件
 * @Author: koujing
 * @Date: 2021-02-26 14:43:28
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-11 17:31:20
-->
<template>
  <div id="app">
    <!-- 路由跳转 -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
// 引入socket
import scenicManagementPlatformConfig from "scenicManagementPlatformConfig";
import systemConfig from "@/config";
import SockJS from "sockjs-client";
import comm from "@/utils/comm";
import { mapGetters } from "vuex";
import Stomp from "stompjs";
import { cloneDeep } from "utils-lite";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      websock: null,
      stompClient: null
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
      messageStatic: "messageStatic"
    }),
    ...mapGetters("system", {
      favicon: "favicon"
    })
  },
  watch: {
    favicon(favicon) {
      // 当favicon变化时，设置浏览器图标
      comm.setWebsiteFavicon(favicon);
    },
    token(token) {
      token && this.getSystemInfo();
    }
  },
  created() {
    this.$store.commit("independent", false);
    // token有效才连接socket
    if (this.$store.getters.token || systemConfig.token) {
      this.initWebSocket();
    }
  },
  beforeDestroy() {
    // 页面离开时断开连接
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
    this.websock = null;
    this.stompClient = null;
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },

    // /**
    //  *获取系统默认favicon、logo、name并存储
    //  */
    getSystemInfo() {
      this.$api.getSystemInfo().then(res => {
        if (res.code === 0) {
          // 存储favicon
          this.$store.commit("system/set", {
            favicon: res.data.favUrl,
            name: res.data.title,
            logo: res.data.logoUrl
          });
          comm.setWebsiteFavicon(res.data.favUrl);
        }
      });
    },

    /**
     *初始化连接socket
     */
    initWebSocket() {
      const socketUrl = scenicManagementPlatformConfig.socketUrl;
      // const socketUrl = 'http://samc.test.daqsoft.com/scenic/scenic_endpoint';
      this.websock = new SockJS(socketUrl);
      // 获取STOMP子协议的客户端对象
      this.stompClient = Stomp.over(this.websock);
      // 处理请求头，添加token字段
      const token =
        sessionStorage.getItem("urlToken") ||
        this.$store.getters.token ||
        systemConfig.token;
      // 定义客户端的认证信息,按需求配置
      const headers = {
        Authorization: `Bearer ${token}`
      };
      // 向服务器发起websocket连接
      this.stompClient.connect(
        headers,
        () => {
          // 订阅消息
          // this.stompClient.subscribe('/topic/greetings', (response) => {
          //   if (response.body) {
          //     console.log(JSON.parse(response.body));
          //   }
          // });
          // 订阅消息-公告接收通知
          this.stompClient.subscribe("/user/queue/notice/receive", message => {
            // console.log(message.body.message);
            const allData = cloneDeep(this.messageStatic);
            const datacont = JSON.parse(message.body);
            const datas = datacont.data || {};
            // console.log('公告接收通知', datacont);
            if (datacont.type && datacont.type === 1) {
              this.$store.commit("showMessage", datas || {});
              allData.forEach(itm => {
                if (itm.type === datas.noticeType) {
                  itm.num = Number(itm.num || 0) + 1;
                }
              });
              this.$store.commit("messageStatic", allData || []);
            } else {
              this.$store.commit("refreshNum", false);
              setTimeout(() => {
                this.$store.commit("refreshNum", true);
              }, 100);
            }
          });
          // 订阅消息-用户权限变更
          this.stompClient.subscribe('/user/queue/role/modify', (message) => {
            console.log('message: ', message);
            // console.log(message.body.message);
            if (message.body) {
              this.changeAuth('您的数据权限已变更，请点击确定刷新系统权限数据');
            }
          });
          // 订阅消息
          // this.stompClient.subscribe('/topic/to/user', function (message) {
          //   console.log(message.body);
          // });
          // 发送消息
          // this.stompClient.send('/app/hello', headers, JSON.stringify({}));
        },
        () => {
          // 连接发生错误时的处理函数
        }
      );
    },
    /**
     * 用户权限变更弹窗
     */
     changeAuth(content) {
      const that = this;
      this.$alert(
        `<div class="daq-message-box">
          <p class="title">数据权限变更</p>
          <p class="content">${ content }</p>
        </div>`,
        {
          title: '提示',
          showCancelButton: false,
          dangerouslyUseHTMLString: true,
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              that.$router.go(0);
              window.parent.location.reload();
            } else {
              that.$router.go(0);
              window.parent.location.reload();
              done();
            }
          }
        }
      ).then(() => {
        // console.log();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
