var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-first" },
    _vm._l(_vm.levelListCopy, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "menu-first-icons",
          class: "icon" + index,
          style: {
            transform: _vm.isExpand || item.active ? "scale(1)" : "scale(0.8)",
          },
        },
        [
          _vm.levelList.length % 2 === 0 && index === 0
            ? _c("div")
            : _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.firstIconClick(item, index, $event)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "child",
                    class: _vm.initAnimotionClass(index),
                    attrs: { src: item.iconUrl },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "name",
                      class: _vm.initAnimotionClass(index),
                      style: { color: item.active ? "#fff" : "#ecf7ff" },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.active,
                          expression: "item.active",
                        },
                      ],
                      staticClass: "circles",
                    },
                    [
                      _c("span", { staticClass: "circle circle1" }),
                      _c("span", { staticClass: "circle circle2" }),
                      _c("span", { staticClass: "circle circle3" }),
                    ]
                  ),
                ]
              ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }