var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "dropdown", staticClass: "cp-site-dropdown" },
    [
      _c(
        "div",
        { staticClass: "cp-site-dropdown__btn", on: { click: _vm.openList } },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.site.scenicShortName || _vm.site.companyName)),
          ]),
          _c("i", { staticClass: "cp-icon cp-site-dropdown__after-icon" }, [
            _vm._v(""),
          ]),
        ]
      ),
      _vm.groups.length || _vm.scenic.length
        ? _c(
            "el-dialog",
            {
              ref: "siteList",
              attrs: {
                visible: _vm.showList,
                modal: false,
                "modal-append-to-body": true,
                "close-on-click-modal": true,
                "show-close": false,
                top: "0",
                "custom-class": "cp-site-list-dialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showList = $event
                },
                close: function ($event) {
                  _vm.showList = false
                },
              },
            },
            [
              _c("div", { staticClass: "cp-site-list-title" }, [
                _c("span", [_vm._v("切换")]),
              ]),
              _c(
                "ul",
                {
                  staticClass: "cp-site-dropdown-menu",
                  style: _vm.siteDropdownMenuStyle,
                },
                [
                  _vm.groups.length
                    ? _c(
                        "li",
                        { staticClass: "cp-site-dropdown-menu__group" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "cp-site-dropdown-menu__group-name",
                            },
                            [_vm._v("集团")]
                          ),
                          _c(
                            "ul",
                            _vm._l(_vm.groups, function (item) {
                              return _c(
                                "li",
                                {
                                  key: `group-${item.id}-${item.accountId}`,
                                  staticClass:
                                    "cp-site-dropdown-menu__item site-dropdown-menu-item",
                                  class: {
                                    "is-activesite":
                                      item.id === _vm.site.id &&
                                      item.accountId === _vm.site.accountId,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSite(item)
                                    },
                                  },
                                },
                                [
                                  _c("p", [_vm._v(_vm._s(item.companyName))]),
                                  _vm.groupNum > 1
                                    ? _c("p", [
                                        _vm._v(
                                          " " + _vm._s(item.empName) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.scenic.length
                    ? _c(
                        "li",
                        { staticClass: "cp-site-dropdown-menu__group" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "cp-site-dropdown-menu__group-name",
                            },
                            [_vm._v("景区")]
                          ),
                          _c(
                            "ul",
                            _vm._l(_vm.scenic, function (item) {
                              return _c(
                                "li",
                                {
                                  key: `scenic-${item.id}-${item.accountId}`,
                                  staticClass:
                                    "cp-site-dropdown-menu__item site-dropdown-menu-item",
                                  class: {
                                    "is-activesite":
                                      item.id === _vm.site.id &&
                                      item.accountId === _vm.site.accountId,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSite(item)
                                    },
                                  },
                                },
                                [
                                  _c("p", [_vm._v(_vm._s(item.scenicName))]),
                                  _vm.companyNum > 1
                                    ? _c("p", [
                                        _vm._v(
                                          " " + _vm._s(item.empName) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.groups.length
        ? _c(
            "el-dialog",
            {
              ref: "defaultSitePanel",
              attrs: {
                visible: _vm.showDefaultSiteDialog,
                title: "设置默认站点",
                modal: false,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "show-close": true,
                top: "0",
                "custom-class": "cp-default-site-panel",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDefaultSiteDialog = $event
                },
                close: function ($event) {
                  _vm.showDefaultSiteDialog = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "cp-default-site-panel__content",
                  style: { maxHeight: _vm.defaultSitePanelContentMaxHeight },
                },
                [
                  _c(
                    "div",
                    { staticClass: "cp-default-site-panel__group" },
                    [
                      _c(
                        "span",
                        { staticClass: "cp-default-site-panel__group-title" },
                        [_vm._v("集团")]
                      ),
                      _vm._l(_vm.groups, function (item) {
                        return _c(
                          "el-radio",
                          {
                            key: item.id,
                            attrs: { label: item.id },
                            model: {
                              value: _vm.site.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.site, "id", $$v)
                              },
                              expression: "site.id",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.companyName) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showDefaultSiteDialog = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.setDefaultSite },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }