<!-- eslint-disable -->
<template>
  <div class="cp-header">
    <div class="cp-header__info cp-header__infoleft">
      <div class="cp-header__logo cp-header__noshrink">
        <router-link v-if="systemLogo" :to="indexRouter">
          <img :src="systemLogo" />
        </router-link>
      </div>
      <div
        v-if="systemName"
        class="cp-header__title cp-header__split cp-header__noshrink"
      >
        <span>{{ systemName }}</span>
      </div>
      <div ref="headermenusoutbox" class="cp-header__menuboxes">
        <span
          v-if="isShowArrow"
          class="el-icon-arrow-left cp-header__icons"
          @click="rightFunc(true)"
        ></span>
        <div ref="headermenus" class="cp-header__menuboxcont">
          <div
            ref="headermenusbox"
            class="cp-header__menubox"
            :style="{ left: -leftX + 'px' }"
          >
            <div
              v-for="item in systems"
              :key="item.mId"
              :class="{ 'is-active': item.mId === systemIds }"
              class="cp-header__menubox-item"
              @click="goMenu(item)"
            >
              <i class="cp-icon" v-html="item.icon"></i>
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <span
          v-if="isShowArrow"
          class="el-icon-arrow-right cp-header__icons"
          @click="rightFunc(false)"
        ></span>
      </div>
      <div
        v-if="site.groups.length || site.scenic.length"
        class="cp-header__info-item cp-header__noshrink"
      >
        <cp-site-dropdown
          v-if="currentSite"
          :value="currentSite"
          v-bind="site"
        ></cp-site-dropdown>
      </div>
      <!-- <div class="cp-header__message cp-header__info-item cp-header__noshrink">
        <img class="imgbox"
             src="../../../assets/images/message_new.png" />
        <span class="cp-number">99</span>
      </div> -->
      <el-dropdown
        v-if="messageStaticNum > 0"
        class="cp-header__message cp-header__info-item cp-header__noshrink"
        placement="bottom"
        @command="getMessageDetail"
      >
        <img class="imgbox" src="../../../assets/images/message_new.png" />
        <span class="cp-number">{{
          messageStaticNum > 99 ? 99 : messageStaticNum
        }}</span>
        <el-dropdown-menu slot="dropdown" class="cp-header-dropdown-menu">
          <el-dropdown-item
            v-for="(item, index) in messageStatic"
            :key="index"
            :command="item"
          >
            <template>
              <span>{{ item.name }}</span>
              (<span style="color: red;">{{ item.num }}</span
              >)
            </template>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown
        class="cp-header__user cp-header__info-item cp-header__noshrink"
        @command="userMenu"
      >
        <span class="el-dropdown-link cp-header__info-btn">
          {{ userInfo.name || "用户"
          }}<i
            class="cp-icon cp-header__more-icon"
            :class="{ 'not-show': !localDeployment || isDaqsoftOs }"
            >&#xea56;</i
          >
        </span>
        <el-dropdown-menu
          v-if="localDeployment && !isDaqsoftOs"
          slot="dropdown"
          class="cp-header-dropdown-menu"
        >
          <el-dropdown-item command="changePassword">
            修改密码
          </el-dropdown-item>
          <el-dropdown-item command="quit">
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <change-password
      v-if="localDeployment && ischangepwd"
      :changepwd="ischangepwd"
      @closeDiag="closeDiag"
    ></change-password>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { cloneDeep } from "utils-lite";
import CpSiteDropdown from "@/components/header/src/site-dropdown";
import { mapGetters } from "vuex";
import config from "@/config";
import ChangePassword from "./changePassword.vue";
import comm from "@/utils/comm.js";
export default {
  name: "CpHeader",
  components: {
    CpSiteDropdown,
    ChangePassword
  },
  props: {
    indexRouter: {
      type: String,
      default: "/index"
    },
    systems: {
      type: Array,
      default() {
        return [];
      }
    },
    site: {
      type: Object,
      default() {
        return {
          groups: [],
          scenic: [],
          defaultCompany: {}
        };
      }
    }
  },
  /* eslint-disable */
  data() {
    return {
      // 是否在大旗OS内打开
      isDaqsoftOs: comm.isDaqsoftOs(),
      systemIds: 0,
      // 向左移动距离
      leftX: 0,
      // 左右翻页箭头
      isShowArrow: false,
      // 是否修改密码
      ischangepwd: false,
      // 是否是本地部署
      localDeployment: false
    };
  },
  /* eslint-disable */
  computed: {
    ...mapGetters({
      user: "user",
      siteId: "siteId",
      systemId: "systemId",
      messageStatic: "messageStatic"
    }),
    currentSite() {
      let site = this.site.scenic.find(
        item =>
          item.id === this.siteId && item.accountId === this.user.account.id
      );
      if (!site) {
        site = this.site.groups.find(
          item =>
            item.id === this.siteId && item.accountId === this.user.account.id
        );
        if (!site) {
          return null;
        }
      }
      return site;
    },
    userInfo() {
      return (this.user && this.user.employee) || {};
    },
    systemLogo: {
      get() {
        return this.$store.state.system.logo || '';
      }
    },
    systemName: {
      get() {
        return (
          this.$store.state.system.globalname || this.$store.state.system.name
        );
      }
    },
    messageStaticNum() {
      let sum = this.messageStatic.reduce(function(prev, cur) {
        return cur.num + prev;
      }, 0);
      return sum || 0;
    }
  },
  watch: {
    systems: {
      handler(val) {
        if (val && val.length > 0) {
          this.init();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (window.scenicManagementPlatformConfig.isLocal) {
      // 巫山项目
      this.localDeployment = true;
    } else {
      this.localDeployment = false;
    }

    // if (this.systems && this.systems.length > 0) {
    //    this.systemIds = this.systems[0].mId;
    // }
    this.init();
    let that = this;
    window.onresize = () => {
      that.initMenu();
    };
    this.$nextTick(() => {
      setTimeout(() => {
        this.initMenu();
      }, 1000);
    });
  },
  methods: {
    // 初始化菜单
    init() {
      if (!this.systemId) {
        let items = this.systems[0];
        this.goMenu(items);
      } else {
        let items = this.systems.find(itm => itm.mId === this.systemId);
        this.goMenu(items);
      }
    },
    // 初始化
    initMenu() {
      // console.log(this.$refs.headermenusbox.clientWidth, this.$refs.headermenus.clientWidth);
      if (
        this.$refs.headermenus.clientWidth >=
        this.$refs.headermenusbox.clientWidth
      ) {
        this.leftX = 0;
        this.isShowArrow = false;
      } else {
        this.isShowArrow = true;
      }
    },
    getMessageDetail(command) {
      let items = cloneDeep(
        this.systems.find(itm => itm.route === "/informationdelivery/")
      );
      if (command.type === "SCENIC_NOTICE") {
        items.route = "/informationdelivery/internalnotification/";
      } else {
        items.route = `/informationdelivery/announcementinformation/index?type=${command.type}`;
      }
      this.goMenu(items);
      sessionStorage.setItem("messageDeatil", command.type);
      setTimeout(() => {
        this.$store.commit("systemUrl", items.route);
      }, 200);
    },
    userMenu(command) {
      switch (command) {
        case "userInfo":
          window.open("http://test.jkxds.com/#/business/account");
          break;
        case "quit":
          this.$confirm("即将退出系统, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.$api
                .loginOut()
                .then(() => {
                  this.$store.commit("clear");
                  this.$store.commit("token", "");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      sessionStorage.removeItem("iframe");
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                      // this.$router.push('/login');
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                })
                .catch(data => {
                  this.$notice.operateError("提示", `${data.message}`);
                  this.$store.commit("clear");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      sessionStorage.removeItem("iframe");
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                });
            })
            .catch(() => {
              // 取消
            });
          break;
        case "changePassword":
          this.ischangepwd = true;
          break;
        default:
          break;
      }
    },
    // 关闭修改密码框
    closeDiag(param) {
      this.ischangepwd = param;
    },
    // 菜单跳转
    goMenu(item) {
      sessionStorage.setItem("messageDeatil", "");
      if (item) {
        this.$store.commit("isThridSys", false);
        if (item.thirdFlag === 1 && item.thirdUrl) {
          this.$store.commit("isThridSys", true);
          window.open(item.thirdUrl, "_blank");
        } else {
          this.systemIds = item.mId;
          this.$store.commit("systemId", item.mId);
        }
      }
    },
    // 右移菜单
    rightFunc(param) {
      this.$nextTick(() => {
        let outerWidth = this.$refs.headermenus.clientWidth;
        if (param) {
          if (this.leftX <= 0) {
            return false;
          }
          if (this.leftX < outerWidth) {
            this.leftX = 0;
          } else {
            this.leftX -= outerWidth;
          }
        } else {
          let innerWidth = this.$refs.headermenusbox.clientWidth;
          if (Math.abs(this.leftX) + outerWidth >= innerWidth) {
            return false;
          } else {
            if (Math.abs(this.leftX) + outerWidth >= innerWidth - outerWidth) {
              this.leftX = innerWidth - outerWidth;
            } else {
              this.leftX += outerWidth;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
@include b(header) {
  // background-color: $primary;
  background-color: #5e6cff;
  display: flex;
  height: 60px;
  justify-content: space-between;
  @include e(menuboxes) {
    flex: 1;
    // width: 400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;
    margin: 0 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  @include e(menuboxcont) {
    // width: 360px;
    width: calc(100% - 40px);
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  @include e(icons) {
    width: 20px;
    height: 100%;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
  }
  @include e(menubox) {
    // flex: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
    overflow: hidden;
    @include e(menubox-item) {
      height: 34px;
      line-height: 34px;
      padding: 0 25px;
      cursor: pointer;
      font-size: $f14;
      white-space: nowrap;
      color: #e6e6f7;
      > span {
        margin-left: 5px;
        user-select: none;
      }
      .cp-icon {
        font-size: $f14;
      }
      &:hover {
        background-color: #4654eb;
        color: #fff;
        border-radius: 17px;
        // font-weight: bold;
      }
    }
  }
  // 主菜单
  .is-active {
    background-color: #4654eb;
    color: #fff;
    border-radius: 17px;
    // font-weight: bold;
  }
  // 右边景区版，集团版切换
  .is-activesite {
    background-color: #4654eb;
    color: #fff;
    border-radius: 0px;
  }
  @include e(info) {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    @include e(info-item) {
      color: #fff;
      cursor: pointer;
      flex: none;
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      margin: 0 25px;

      @include e(message) {
        width: 20px;
        position: relative;
        .cp-icon {
          position: absolute;
          left: 0;
          top: 2px;
          width: 100%;
          height: 100%;
          font-size: 20px;
          // margin-right: 0;
        }
        .imgbox {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 20px;
          height: auto;
          // font-size: 20px;
        }
        .cp-number {
          position: absolute;
          right: -9px;
          top: 8px;
          font-size: $f12;
          border-radius: 50%;
          padding: 0px 3px;
          min-width: 18px;
          height: 18px;
          line-height: 17px;
          text-align: center;
          overflow: hidden;
          background: #ff2e3c;
        }
      }

      @include e(user) {
        @include e(info-btn) {
          display: inline-block;
          height: 100%;
        }

        @include e(more-icon) {
          &.cp-icon {
            font-size: 12px;
            margin-left: 15px;
            top: 0;
          }
        }
      }

      @include e(cloud-icon) {
        margin-right: 5px;
      }

      &::before {
        background-color: $--color-primary-light-2;
        content: "";
        display: inline-block;
        height: 15px;
        left: -25px;
        position: relative;
        top: 3px;
        width: 1px;
      }

      // 图标偏移纠正
      > .cp-icon {
        // font-size: 16px;
        position: relative;
        // top: 1px;
      }
    }
  }
  @include e(infoleft) {
    height: 100%;
    flex: 1;
  }
  @include e(logo) {
    margin: 0 15px 0 22px;
    img {
      max-height: 32px;
    }
  }

  @include e(title) {
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    margin: 0 30px 0 15px;

    &::before {
      left: -15px;
    }
  }
  @include e(noshrink) {
    flex-shrink: 0;
  }

  @include e(split) {
    &::before {
      background-color: $--color-primary-light-2;
      content: "";
      display: inline-block;
      height: 15px;
      position: relative;
      top: 2px;
      width: 1px;
    }
  }
}

@include b(header-dropdown-menu) {
  &.el-dropdown-menu {
    margin: 0;
  }
}
</style>
