const state = {
  // 子系统是否是独立的
  independent: false,
  // 登录认证code
  authCode: '',
  // 系统token
  token: '',
  // 系统用户
  user: {
    account: {},
    umsCompany: {}
  },
  // 记录选择的子系统
  systemId: '',
  systemUrl: '',
  // 站点id
  siteId: '',
  // 记录消息汇总
  messageStatic: [],
  // 消息详情
  messageDeatil: {},
  // 刷新消息总数
  refreshNum: false,
  // 展示消息
  showMessage: false,
  // 三方链接
  isThridSys: false
};

export default state;
