<template>
  <div class="anime-bg-box">
    <div class="bg-down"></div>
    <div class="bg-up"></div>
    <img class="bg-icon4"
         src="../../../assets/images/wgs/bg-icon4.png">
    <img class="bg-icon1"
         src="../../../assets/images/wgs/bg-icon1.png">
    <img class="bg-icon2"
         src="../../../assets/images/wgs/bg-icon2.png">
    <img class="bg-icon3"
         src="../../../assets/images/wgs/bg-icon3.png">
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimeBg',
  data() {
    return {};
  },
  mounted() {
    anime({
      targets: '.anime-bg-box .bg-icon4',
      translateX: -30,
      translateY: 30,
      easing: 'easeInOutSine',
      duration: 3000,
      direction: 'alternate',
      loop: true
    });
    anime({
      targets: '.anime-bg-box .bg-icon2,.anime-bg-box .bg-icon3',
      translateX: 10,
      translateY: -30,
      easing: 'easeInOutSine',
      duration: 3000,
      direction: 'alternate',
      loop: true
    });
    anime({
      targets: '.anime-bg-box .bg-icon1',
      translateX: -10,
      translateY: 30,
      scale: 0.9,
      easing: 'easeInOutSine',
      duration: 3000,
      direction: 'alternate',
      loop: true
    });
  }
};
</script>

<style scoped lang="scss">
.anime-bg-box {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .bg-down,
  .bg-up {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .bg-down {
    background: url(../../../assets/images/wgs/bg.png);
    background-size:cover;
    background-position:center;
    z-index: 1;
  }
  .bg-up {
    background: url(../../../assets/images/wgs/bg_.png);
    z-index: 3;
  }
  .bg-icon1,.bg-icon2,.bg-icon3,.bg-icon4{
    position: absolute;
    z-index: 2;
  }
  .bg-icon4{
    right: 270px;
    top: 430px;
    width: 102px;
  }
  .bg-icon1{
    top: 150px;
    left: 500px;
    // width: 203px;
  }
  .bg-icon2{
    top: 450px;
    left: 370px;
    // width: 73px;
  }
  .bg-icon3{
    top: 700px;
    left: 80px;
    // width: 203px;
  }
}
</style>
