<template>
  <!-- eslint-disable  -->
  <div>
    <template v-if="dialogVisible">
      <iframe id="gameFrameparent" v-loading.fullscreen.lock="loading" element-loading-text="正在加载，请稍后" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
        class="frame" :src="url" width="100%" height="100%" @load="loading = false"></iframe>
      <div ref="eventdetail" class="btn" @mousedown="mouseDownHandleelse($event)" @mouseup="mouseUpHandleelse( $event)">
        <i class="cp-icon" title="返回导览页">&#xeb54;</i>
      </div>
    </template>
    <template v-else>
      <div class="cp-preview__bigbg" v-if="!levelFlag">
        <img class="logo" :src="centerLogo.logoUrl">
        <div class="first-nav" v-if="isFirstShow">
          <ul>
            <li v-for="item in firstList" :key="item.id" @click="levelOneClick(item)">
              <a href="javascript:;"><img :src="item.iconUrl" class="ued-datav">
                <p> {{ item.name.substr(0,5) }}</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="seconde-nav" v-if="isSeconedShow">
          <ul>
            <div style="display:none;">{{down = Math.ceil(sconedData.length / 2)}} {{up = 1}}</div>
            <template v-for="item in sconedData">
              <li @click="levelTwoClick(item)" :key="item.id" :class="'item'+(down * 2 - 1)" v-if="down>=1">
                <a href="javascript:;">
                  <img :src="item.iconUrl" class="ued-datav">
                  <p style="padding-top: 5px;">{{ item.name.substr(0,5) }}</p>
                </a>
                <div style="display:none;">{{down--}}</div>
              </li>
              <li @click="levelTwoClick(item)" :key="item.id" :class="'item'+(up * 2)" v-else>
                <a href="javascript:;">
                  <img :src="item.iconUrl" class="ued-datav">
                  <p style="padding-top: 5px;">{{ item.name.substr(0,5) }}</p>
                </a>
                <div style="display:none;">{{up++}}</div>
              </li>
            </template>
          </ul>
        </div>
        <div class="three-nav" v-if="isThreeShow">
          <ul>
            <li v-for="item in threeData" :key="item.id" @click="goUrl(item.thirdUrl,item.newWindowFlag)">
              <a href="javascript:;">
                <img :src="item.iconUrl" class="ued-datav">
                <p>{{item.name}}</p>
              </a></li>
          </ul>
        </div>
        <div class="goback" v-if="!isFirstShow" @click="toLevelOne"><i class="ued-datav cp-icon">&#xe923;</i><label>返回</label></div>
      </div>
      <div class="cp-preview__all" v-else>
        <img src="@/assets/images/xianhai/title.png" class="logo">
        <ul>
          <li @click="goUrl(item.thirdUrl,item.newWindowFlag)" v-for="(item,index) in levelList" :key="item.id" :class="'item'+index" :style="{'background':(index>=30 ? randomBackground(index):'')}">
            <a>
              <img :src="item.iconUrl" class="ued-datav">
              <p> {{ item.name.substr(0,5) }}</p>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

 <script>
/*eslint-disable */
export default {
  name: 'XianhaiGuide',
  props: {
    // 层级菜单
    levelList: {
      type: Array,
      default() {
        return [];
      }
    },
    levelFlag: {
      type: Boolean,
      default: false
    },
    centerLogo: {
      type: Object,
      default() {
        return {};
      }
    },
    screenWidth: {
      type: String,
      default: ''
    },
    screenHeight: {
      type: String,
      default: ''
    },
    isshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      backgrounds: [
        '#506dff',
        '#20ba4f',
        '#ff703e',
        '#7d6cfa',
        '#19a4ec',
        '#506dff',
        '#ffa221',
        '#ff703e',
        '#7d6cfa',
        '#19a4ec',
        '#4dbb0d',
        '#1ab6e1',
        '#19bc7d',
        '#2d8afd',
        '#81b024',
        '#4dbb0d',
        '#1ab6e1',
        '#19bc7d',
        '#2d8afd',
        '#ffa221',
        '#f249e5',
        '#2d8afd',
        '#a25eff',
        '#fe5d5d',
        '#ffa221',
        '#de5f83',
        '#2d8afd',
        '#a25eff',
        '#fe5d5d',
        '#ffa221'
      ],
      loading: true,
      isFirstShow: true,
      isSeconedShow: false,
      isThreeShow: false,
      sconedData: [],
      threeData: [],
      dialogVisible: false,
      url: '',
      // 拖动位置
      moveDataelse: {
        x: null,
        y: null
      }
    };
  },
  computed: {
    firstList() {
      return this.levelList.slice(0, 4);
    }
  },
  methods: {
    randomBackground(index) {
      const i = Math.floor(Math.random() * 30);
      return this.backgrounds[i];
    },
    levelOneClick(item) {
      if (item.thirdUrl) return this.goUrl(item.thirdUrl, item.newWindowFlag);
      if (!item.childList || item.childList.length == 0) return;
      this.sconedData = item.childList || [];
      this.isFirstShow = false;
      this.$nextTick(() => (this.isSeconedShow = true));
    },
    levelTwoClick(item) {
      if (item.thirdUrl) return this.goUrl(item.thirdUrl, item.newWindowFlag);
      if (!item.childList || item.childList.length == 0) return;
      this.isThreeShow = false;
      this.$nextTick(() => {
        this.threeData = item.childList || [];
        this.isFirstShow = false;
        this.$nextTick(() => (this.isThreeShow = true));
      });
    },
    toLevelOne() {
      this.isThreeShow = false;
      this.isSeconedShow = false;
      this.isFirstShow = true;
    },
    //
    mouseDownHandleelse(event) {
      this.firstTime = new Date().getTime();
      const iframDiv = document.getElementById('gameFrameparent');
      iframDiv.style.pointerEvents = 'none';
      this.moveDataelse.x = event.pageX - this.$refs.eventdetail.offsetLeft;
      this.moveDataelse.y = event.pageY - this.$refs.eventdetail.offsetTop;
      // event.currentTarget.style.cursor = 'move';
      window.onmousemove = this.mouseMoveHandleelse;
    },
    mouseMoveHandleelse(event) {
      // 事件详情才能拖动
      const offsetwidth = this.$refs.eventdetail.offsetWidth;
      const offsetheight = this.$refs.eventdetail.offsetHeight;
      // 浏览器宽高
      const clientwidth = document.documentElement.clientWidth;
      const clientheight = document.documentElement.clientHeight;
      let moveLeft = event.pageX - this.moveDataelse.x;
      let moveTop = event.pageY - this.moveDataelse.y;
      if (moveLeft < 0) {
        moveLeft = 0;
      } else if (clientwidth < moveLeft + offsetwidth) {
        moveLeft = clientwidth - offsetwidth;
      }
      if (moveTop < 0) {
        moveTop = 0;
      } else if (clientheight < moveTop + offsetheight) {
        moveTop = clientheight - offsetheight;
      }

      this.$refs.eventdetail.style.left = `${moveLeft}px`;
      this.$refs.eventdetail.style.top = `${moveTop}px`;
    },
    mouseUpHandleelse() {
      const iframDiv = document.getElementById('gameFrameparent');
      iframDiv.style.pointerEvents = 'auto';
      window.onmousemove = null;
      const lastTime = new Date().getTime();
      if (lastTime - this.firstTime < 200) {
        this.dialogVisible = false;
        sessionStorage.removeItem('iframe');
        this.$emit('refresh', true);
      }
    },
    // 打开新窗口页面
    goUrl(url, newWindowFlag) {
      if (!url) return;
      if (newWindowFlag == 0) {
        location.href = url;
      } else if (newWindowFlag == 1) {
        window.open(url);
      } else if (newWindowFlag == 2) {
        sessionStorage.setItem('iframe', true);
        this.dialogVisible = false;
        this.loading = true;
        this.url = url;
        this.dialogVisible = true;
        setTimeout(() => {
          this.dialogVisible = true;
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './xianhai-guide.scss';
.frame {
  position: absolute;
  z-index: 999;
  border: none;
}
.btn {
  position: fixed;
  z-index: 9999;
  width: 60px;
  height: 60px;
  background: #0d182f;
  border-radius: 50%;
  right: 400px;
  top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  opacity: 0.4;
  cursor: default;
  i {
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
  }
}
</style>
