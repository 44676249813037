import { MessageBox, Notification } from 'element-ui';
import '@/assets/scss/message-box.scss';

function tips(title = '提示信息', message = '') {
  // eslint-disable-next-line new-cap
  Notification({
    title,
    message,
    duration: 3000,
    position: 'bottom-right'
  });
}

function messageBox(title = '', message = '', type = 'success') {
  MessageBox.alert(
    `<div class="daq-message-box">
          <p class="title">${ title }</p>
          <p class="content">${ message }</p>
        </div>`,
    {
      title: '提示',
      dangerouslyUseHTMLString: true,
      type
    }
  );
}

const notice = {
  /**
   * 普通消息通知
   * @param config
   */
  normal(config) {
    MessageBox.confirm(config.content, config.title, {
      showCancelButton: false,
      showConfirmButton: false,
      dangerouslyUseHTMLString: config.html || false,
      callback() {
        // 关闭消息
      }
    });
  },

  /**
   * 重要消息通知
   * @param config
   */
  important(config) {
    MessageBox.confirm(config.content, config.title, {
      dangerouslyUseHTMLString: config.html || false,
      showClose: false,
      showCancelButton: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      confirmButtonText: '我已知晓'
    });
  },

  /**
   * 操作错误
   * @param data
   */
  operateError(title, message) {
    tips(title || '失败', message, 'error');
  },

  /**
   * 操作成功
   * @param data
   */
  operateSuccess(title, message) {
    tips(title || '成功', message, 'success');
  },

  /**
   * 操作提示信息
   * @param data
   */
  operateInfo(title, message) {
    tips(title || '信息', message, 'info');
  },

  /**
   * 操作警告信息
   * @param data
   */
  operateWarning(title, message) {
    tips(title || '警告', message, 'warning');
  },

  popupSuccess(title, message) {
    messageBox(title, message, 'success');
  },
  popupError(title, message) {
    messageBox(title, message, 'error');
  },
  popupWarning(title, message) {
    messageBox(title, message, 'warning');
  },
  popupInfo(title, message) {
    messageBox(title, message, 'info');
  },

  /**
   * 全局提示
   * @param option
   * {
   *   title: 标题
   *   message: {
   *     title: 消息标题  html
   *     content: 消息内容
   *   }
   * }
   */
  globalTip(option) {
    // eslint-disable-next-line new-cap
    Notification({
      title: option.title,
      dangerouslyUseHTMLString: true,
      position: 'bottom-right',
      // 设置为0不会自动关闭
      duration: 0,
      message: `
          <div class="daq-global-tips">
            <p class="title">${ option.message.title }</p>
            <p class="content">${ option.message.content }</p>
          </div>
          `,
      // eslint-disable-next-line no-empty-function
      onClick: option.onClick || function onClick() {
      }
    });
  }
};

export default notice;
