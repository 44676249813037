var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "particle-box" },
    _vm._l(_vm.count, function (n) {
      return _c("span", {
        key: n,
        staticClass: "particle",
        class: "particle" + n,
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }