<template>
  <div class="cp-sidebar"
       :class="{'cp-sidebar--has-children': subMenus.length}">
    <div class="cp-side-top-menu">
      <div class="cp-side-container"
           :class="{active: click}">
        <div class="cp-side-menu-extend-btn">
          <i class="cp-icon">&#xea9d;</i>
        </div>
        <ul class="cp-side-menu">
          <li v-for="menu in invalidMenus"
              :key="menu.mId"
              class="cp-side-menu__item"
              :class="{'is-active': activeMenuId === menu.mId}"
              @click="selectMenu(menu)">
            <i class="cp-icon cp-side-menu__item-icon"
               v-html="menu.icon"></i>
            <span class="cp-side-menu__item-title">{{ menu.label }}</span>
          </li>
        </ul>
      </div>
    </div>
    <cp-side-menu-tree
      v-if="subMenus.length"
      :default-id-chain="subActiveChain"
      :menus="subMenus">
    </cp-side-menu-tree>
  </div>
</template>

<script>
import { cloneDeep } from 'utils-lite';
import CpSideMenuTree from '@/components/sidebar/src/side-menu-tree';
export default {
  name: 'CpSidebar',
  components: { CpSideMenuTree },
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      }
    },
    defaultIdChain: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      click: false,
      isCollapse: true,
      activeMenuId: '',
      activeIdChain: []
    };
  },
  computed: {
    invalidMenus() {
      function filterHideMenu(menus) {
        // 菜单类型：1-目录，2-菜单，3-按钮，按钮类型不在侧边菜单栏显示
        const temp = menus.filter(menu => menu.type !== 3);
        temp.forEach((item) => {
          if (item.children && item.children.length) {
            item.children = filterHideMenu(item.children);
          }
        });
        return temp;
      }

      return filterHideMenu(cloneDeep(this.menus));
    },
    subMenus() {
      console.log(75555, this.invalidMenus);
      const topMenu = this.invalidMenus.find(menu => menu.mId === this.activeMenuId);
      console.log(77777, topMenu);
      return topMenu && topMenu.children || [];
    },
    subActiveChain() {
      return this.activeIdChain.reduce((chain, id, index) => {
        if (index > 0) {
          chain.push(id);
        }

        return chain;
      }, []);
    }
  },
  watch: {
    '$store.state.refreshNum': {
      handler(val) {
        console.log(62222, val);
      },
      deep: true
    },
    defaultIdChain: {
      immediate: true,
      deep: true,
      handler(ids) {
        this.activeIdChain = cloneDeep(ids);
        this.activeMenuId = this.activeIdChain[0] || '';
      }
    }
  },
  methods: {
    selectMenu(menu) {
      this.activeMenuId = menu.mId;
      this.activeIdChain = [menu.mId];

      while (!menu.route && menu.children && menu.children.length) {
        menu = menu.children[0] || {};
      }
      if (menu.route) {
        this.$router.push({
          path: menu.route
        });
      }

      this.click = true;
      setTimeout(() => {
        this.click = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
@include b(sidebar) {
  position: relative;
  width: 48px;
  height: 100%;
  z-index: 99;
  display: flex;

  @include m(has-children) {
    width: 240px;
  }
}
@include b(side-top-menu) {
  width: 48px;
  height: 100%;
  z-index: 99;
}

@include b(side-container) {
  width: 48px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  overflow: hidden;
  background-color: #fff;
  transition: width .3s linear;

  &:hover {
    width: 240px;
    transition: width .3s linear;

    .cp-side-menu-extend-btn {
      .cp-icon {
        left: -2px;
        transform: rotate(90deg);
        transition: transform .3s linear;
      }
    }
    &.active {
      width: 48px;
    }
  }
}

@include b(side-menu-extend-btn) {
  width: 100%;
  height: 42px;
  border-radius: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 15px;
  cursor: pointer;
  color: #333;

  .cp-icon {
    position: relative;
    display: inline-block;
    font-size: 12px;
    transform: rotate(0deg);
    transition: transform .3s linear;
  }
}

@include b(side-menu) {
  @include e(item) {
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    @include when(active) {
      color: $primary;
    }
    &:hover {
      color: $primary;
    }
  }

  @include e(item-icon) {
    display: inline-block;
    width: 46px;
    font-size: 14px;
    padding: 0 15px;
  }

  @include e(item-title) {
    height: 42px;
    line-height: 42px;
    padding: 2px;
    font-size:14px;
  }
}
</style>
