/* eslint-disable max-params */
import axios from "axios";
import systemConfig from "@/config";
import scenicManagementPlatformConfig from "scenicManagementPlatformConfig";
import store from "@/store";

// import notice from '@/utils/notice';
import { MessageBox } from "element-ui";
import "@/assets/scss/message-box.scss";
// 阻止二次弹窗
let timeoutFlag = false;
function errorTips(title, content, header) {
  if (timeoutFlag) return;
  MessageBox.alert(
    `<div class="daq-message-box">
          <p class="title">${title}</p>
          <p class="content">${content}</p>
        </div>`,
    {
      title: header,
      dangerouslyUseHTMLString: true,
      type: "error",
      showClose: false
    }
  ).then(() => {
    timeoutFlag = true;
    store.commit("clear");
    if (systemConfig.localDeployment) {
      // 本地部署
      top.location.href = `${window.scenicManagementPlatformConfig.localEntrance}scenic/login`;
    } else {
      top.location.href = window.scenicManagementPlatformConfig.entrance;
    }
    // location.href = window.scenicManagementPlatformConfig.entrance;
  });
  timeoutFlag = true;
}
// 基础url
let baseURL = scenicManagementPlatformConfig.baseURL;
const abUrl = new RegExp('^https|http');
if (!abUrl.test(baseURL)) {
  baseURL = location.protocol + baseURL;
}
axios.defaults.baseURL = baseURL;
// 请求超时
axios.defaults.timeout = 10000;
// post 请求头的设置
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// eslint-disable-next-line max-params
// eslint-disable-next-line complexity
function dealOptions(method, url, data, config) {
  const headerConfig = {
    headers: {}
  };
  // 判断是否是相对路径，且是模拟数据，则使用本地地址(非模拟数据则使用baseUrl)
  const mockUrl = new RegExp("/mock/([a-zA-z0-9-]+/)*[a-zA-z0-9-]+.json$");
  const abUrl = new RegExp("^https|http");
  if (!abUrl.test(url) && mockUrl.test(url)) {
    url = window.location.origin + url;
  }
  let cloudEntrance = scenicManagementPlatformConfig.cloudEntrance;
  if (!abUrl.test(cloudEntrance)) {
    cloudEntrance = location.protocol + cloudEntrance;
  }
  if (url && url.includes("local-deploy/login/sms-code")) {
    url = `${cloudEntrance}local-deploy/login/sms-code`;
  }

  if (url && url.includes("local-deploy/login/valid-sms-code")) {
    url = `${cloudEntrance}local-deploy/login/valid-sms-code`;
  }
  if (url && url.includes("local-deploy/login/config")) {
    url = `${cloudEntrance}local-deploy/login/config`;
  }
  // 微信扫码登陆
  if (url && url.includes("local-deploy/login/wx-qr/create-param")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/create-param`;
  }
  if (url && url.includes("local-deploy/login/wx-qr/msg/code")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/msg/code`;
  }
  if (url && url.includes("local-deploy/login/wx-qr/bind")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/bind`;
  }
  if (url && url.includes("local-deploy/login/wx-qr/query-bind")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/query-bind`;
  }
  // 手机是否存在
  if (url && url.includes("local-deploy/login/wx-qr/valid-phone")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/valid-phone`;
  }
  // 选择公司调用API
  if (url && url.includes("local-deploy/login/choose-company")) {
    url = `${cloudEntrance}local-deploy/login/choose-company`;
  }
  if (url && url.includes("local-deploy/login/wx-qr/choose-company")) {
    url = `${cloudEntrance}local-deploy/login/wx-qr/choose-company`;
  }

  if (url && url.includes("local-deploy/login/password")) {
    if (url.includes("local-deploy/login/password/encryption")) {
      url = `${cloudEntrance}local-deploy/login/password/encryption`;
    } else {
      url = `${cloudEntrance}local-deploy/login/password`;
    }
  }
  if (url && url.includes("local-deploy/login/captcha")) {
    url = `${cloudEntrance}local-deploy/login/captcha`;
  }
  if (url && url.includes("local-deploy/login/accountPasswordEncry")) {
    url = `${cloudEntrance}local-deploy/login/accountPasswordEncry`;
  }
  // 处理请求头，添加token字段
  const token =
    sessionStorage.getItem("urlToken") ||
    store.getters.token ||
    systemConfig.token;
  if (token) {
    headerConfig.headers[systemConfig.tokenName] = `Bearer ${token}`;
  }

  const options = Object.assign(
    headerConfig,
    {
      // 允许跨域带cookie
      // 服务端需要设置响应头Allow-Credentials=true Allow-Origin不能为* 还得设置下Allow-Methods
      withCredentials: true
    },
    config,
    {
      params: ["get", "delete"].includes(method) ? data : {},
      url,
      method,
      data: ["post", "put"].includes(method) ? data : {}
    }
  );

  options.headers = options.headers || {};
  return options;
}

/* eslint-disable */

const request = (method, url, data, config = {}) => {
  const options = dealOptions(method, url, data, config);

  return new Promise((resolve, reject) => {
    axios
      .request(options)
      .then(res => {
        const data = res.data;
        if (data) {
          // TODO 判断code，如果由后台统一返回错误信息，则这里统一提示；如果不是，则调用的地方在判断code
          if (data.code === 0) {
            resolve(data);
          } else {
            switch (data.code) {
              case 403:
                if (timeoutFlag) return;
                errorTips(
                  "登录会话超时",
                  "会话状态已超时，请重新登录",
                  "超时提示"
                );

                // 防止页面后退
                // history.pushState(null, null, document.URL);
                // window.addEventListener('popstate', () => {
                //   history.pushState(null, null, document.URL);
                // });
                break;
              case 10:
                timeoutFlag = false;
                // TODO 待处理通用提示组件
                // 公司或者账号处于停用状态
                // notice.operateError('用户登录已失效', '公司或者账号处于停用状态');
                errorTips(
                  "用户登录已失效",
                  "公司或者账号处于停用状态",
                  "禁用提示"
                );
                break;
              case 11:
                timeoutFlag = false;
                // 公司或者账号处于注销状态
                // notice.operateError('用户登录已失效', '公司或者账号处于注销状态');
                errorTips(
                  "用户登录已失效",
                  "公司或者账号处于注销状态",
                  "禁用提示"
                );
                break;
              default:
                // reject(res);
                break;
            }
            reject(res);
          }
        } else {
          reject(res);
        }
      })
      .catch(res => {
        // TODO 判断 404 403 401 500
        reject(res);
      });
  });
};

export const ajax = {
  request,
  get(url, params, config) {
    return request("get", url, params, config);
  },
  delete(url, params, config) {
    return request("delete", url, params, config);
  },
  head(url, config) {
    return request("head", url, null, config);
  },
  post(url, data, config) {
    return request("post", url, data, config);
  },
  put(url, data, config) {
    return request("put", url, data, config);
  },
  patch(url, data, config) {
    return request("path", url, data, config);
  },
  setCommonHeader(key, value) {
    axios.defaults.headers.common[key] = value;
  }
};
