var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "menu-child-box" },
    _vm._l(_vm.childrenCopy, function (item, index) {
      return _c(
        "div",
        {
          key: item.name,
          staticClass: "icons menu-scd",
          class: "icon" + index,
          style: { visibility: !_vm.shootRuning ? "visible" : "hidden" },
          on: {
            click: function ($event) {
              return _vm.goUrl(item.thirdUrl)
            },
          },
        },
        [
          _c(
            "p",
            { staticClass: "name", class: _vm.initAnimotionClass(index) },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          ),
          _vm.children.length % 2 === 0 && index === 0
            ? _c("img", {
                staticStyle: { visibility: "hidden" },
                attrs: { src: require("../../../assets/images/wgs/light.png") },
              })
            : _c("img", {
                class: _vm.initAnimotionClass(index),
                attrs: { src: item.iconUrl },
              }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }