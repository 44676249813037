<template>
  <div class="anime-planet">
    <div class="out">
      <img
        class="icon icon0"
        style="width: 1.7vw; top: -0.9vw"
        src="../../../assets/images/wgs/out1.png">
      <img
        class="icon icon1"
        style="width: 2vw;top: -1vw"
        src="../../../assets/images/wgs/out2.png">
      <img
        class="icon icon2"
        style="width: 2.8vw;top: -1.3vw"
        src="../../../assets/images/wgs/out3.png">
      <img
        class="icon icon3"
        style="width: 2.8vw;top: -1.4vw"
        src="../../../assets/images/wgs/out4.png">
    </div>
    <div class="inner">
      <img
        class="icon icon0"
        style="width: 2.8vw;top: -1.3vw"
        src="../../../assets/images/wgs/iner1.png">
      <img
        class="icon icon1"
        style="width: 2.4vw;top: -1.1vw"
        src="../../../assets/images/wgs/iner2.png">
      <img
        class="icon icon2"
        style="width: 1.8vw;top: -0.7vw"
        src="../../../assets/images/wgs/iner3.png">
      <img
        class="icon icon3"
        style="width: 1.8vw;top: -0.75vw"
        src="../../../assets/images/wgs/iner4.png">
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.js';
export default {
  name: 'AnimePlanet',
  data() {
    return {
      animeCase: null,
      durationL: 48000,
      durationS: 16000
    };
  },
  mounted() {
    this.initAnime();
    this.playAnime();
  },
  methods: {
    initAnime() {
      this.animeCase = anime.timeline({
        easing: 'linear',
        loop: true
        // autoplay: true,
      });
    },
    playAnime() {
      const p1 = anime.path('.planet .planet-path1');
      const p2 = anime.path('.planet .planet-path2');
      for (let i = 0; i < 4; i++) {
        const a = anime({
          targets: `.anime-planet .out .icon${ i }`,
          opacity: 1,
          loop: true,
          easing: 'linear',
          translateX: p2('x'),
          translateY: p2('y'),
          duration: this.durationL,
          autoplay: false,
          update(anim) {
            const t = Math.abs(anim.progress - 50);
            anim.set(`.anime-planet .icon${ i }`, { opacity: 1 - t / 50 });
          }
        });
        a.seek(12000 * (i + 1));
        a.play();
      }
      for (let i = 0; i < 4; i++) {
        const a = anime({
          targets: `.anime-planet .inner .icon${ i }`,
          opacity: 1,
          loop: true,
          easing: 'linear',
          translateX: p1('x'),
          translateY: p1('y'),
          duration: this.durationL,
          autoplay: false,
          update(anim) {
            const t = Math.abs(anim.progress - 50);
            anim.set(`.anime-planet .icon${ i }`, { opacity: (1 - t / 50) });
          }
        });
        a.seek(6000 * (2 * i + 1));
        a.play();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.anime-planet {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;

    left: -1.15vw;
  }
}
</style>
