import api from '@/api';
import scenicManagementPlatformConfig from 'scenicManagementPlatformConfig';
/* eslint-disable */
const actions = {
    getUserInfo({ commit }) {
        api.getUserInfo()
            .then((res) => {
                if (res.code === 0) {
                    const user = res.data || {
                        account: {},
                        umsCompany: {}
                    };
                    // 保存用户信息和站点id
                    commit('user', user);
                    if (scenicManagementPlatformConfig.isLocal) {
                        // 本地部署设置浏览器名称
                        document.title = `${ res.data.umsCompany.companyName }`;
                    } else {
                        document.title = `景区大脑 | ${ res.data.umsCompany.companyName } | 大旗云`;
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
};
/* eslint-disable */
export default actions;