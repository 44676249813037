var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-header" },
    [
      _c(
        "div",
        { staticClass: "cp-header__info cp-header__infoleft" },
        [
          _c(
            "div",
            { staticClass: "cp-header__logo cp-header__noshrink" },
            [
              _vm.systemLogo
                ? _c("router-link", { attrs: { to: _vm.indexRouter } }, [
                    _c("img", { attrs: { src: _vm.systemLogo } }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.systemName
            ? _c(
                "div",
                {
                  staticClass:
                    "cp-header__title cp-header__split cp-header__noshrink",
                },
                [_c("span", [_vm._v(_vm._s(_vm.systemName))])]
              )
            : _vm._e(),
          _c(
            "div",
            { ref: "headermenusoutbox", staticClass: "cp-header__menuboxes" },
            [
              _vm.isShowArrow
                ? _c("span", {
                    staticClass: "el-icon-arrow-left cp-header__icons",
                    on: {
                      click: function ($event) {
                        return _vm.rightFunc(true)
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { ref: "headermenus", staticClass: "cp-header__menuboxcont" },
                [
                  _c(
                    "div",
                    {
                      ref: "headermenusbox",
                      staticClass: "cp-header__menubox",
                      style: { left: -_vm.leftX + "px" },
                    },
                    _vm._l(_vm.systems, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.mId,
                          staticClass: "cp-header__menubox-item",
                          class: { "is-active": item.mId === _vm.systemIds },
                          on: {
                            click: function ($event) {
                              return _vm.goMenu(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "cp-icon",
                            domProps: { innerHTML: _vm._s(item.icon) },
                          }),
                          _c("span", [_vm._v(_vm._s(item.label))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm.isShowArrow
                ? _c("span", {
                    staticClass: "el-icon-arrow-right cp-header__icons",
                    on: {
                      click: function ($event) {
                        return _vm.rightFunc(false)
                      },
                    },
                  })
                : _vm._e(),
            ]
          ),
          _vm.site.groups.length || _vm.site.scenic.length
            ? _c(
                "div",
                { staticClass: "cp-header__info-item cp-header__noshrink" },
                [
                  _vm.currentSite
                    ? _c(
                        "cp-site-dropdown",
                        _vm._b(
                          { attrs: { value: _vm.currentSite } },
                          "cp-site-dropdown",
                          _vm.site,
                          false
                        )
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.messageStaticNum > 0
            ? _c(
                "el-dropdown",
                {
                  staticClass:
                    "cp-header__message cp-header__info-item cp-header__noshrink",
                  attrs: { placement: "bottom" },
                  on: { command: _vm.getMessageDetail },
                },
                [
                  _c("img", {
                    staticClass: "imgbox",
                    attrs: {
                      src: require("../../../assets/images/message_new.png"),
                    },
                  }),
                  _c("span", { staticClass: "cp-number" }, [
                    _vm._v(
                      _vm._s(
                        _vm.messageStaticNum > 99 ? 99 : _vm.messageStaticNum
                      )
                    ),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-header-dropdown-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.messageStatic, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item } },
                        [
                          [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _vm._v(" ("),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.num)),
                            ]),
                            _vm._v(") "),
                          ],
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass:
                "cp-header__user cp-header__info-item cp-header__noshrink",
              on: { command: _vm.userMenu },
            },
            [
              _c(
                "span",
                { staticClass: "el-dropdown-link cp-header__info-btn" },
                [
                  _vm._v(" " + _vm._s(_vm.userInfo.name || "用户")),
                  _c(
                    "i",
                    {
                      staticClass: "cp-icon cp-header__more-icon",
                      class: {
                        "not-show": !_vm.localDeployment || _vm.isDaqsoftOs,
                      },
                    },
                    [_vm._v("")]
                  ),
                ]
              ),
              _vm.localDeployment && !_vm.isDaqsoftOs
                ? _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-header-dropdown-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "changePassword" } },
                        [_vm._v(" 修改密码 ")]
                      ),
                      _c("el-dropdown-item", { attrs: { command: "quit" } }, [
                        _vm._v(" 退出 "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.localDeployment && _vm.ischangepwd
        ? _c("change-password", {
            attrs: { changepwd: _vm.ischangepwd },
            on: { closeDiag: _vm.closeDiag },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }