/* eslint-disable consistent-return */
/*
 * @Descripttion: 系统中需要使用到的一些公用方法库[待完善]
 * @Author: koujing
 * @Date: 2021-03-01 17:23:10
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-31 15:31:59
 */

/* eslint-disable */
const comm = {
    /**
     * [获取url上的参数]
     * @param {[String]} name [需要获取参数的变量名]
     * @returns
     */
    getUrlParam(name) {
        const reg = new RegExp(`(^|&)${ name }=([^&]*)(&|$)`);
        const result = window.location.search.substr(1).match(reg);
        return result ? decodeURIComponent(result[2]) : null;
    },

    /**
     * [ellipsisContent 内容长度限制，转换为省略号结尾]
     * @param  {[String]} content [目标内容]
     * @param  {[Number]} length  [限制的长度]
     * @param  {[Number]} after   [从第length个字符开始截取length个字符]
     * @return {[String]}         [超过限制长度的数据则返回限制长度的字符串加上...，没超过则原文返回]
     */
    ellipsisContent(content, length, after) {
        let result = '';
        if (!content || typeof content !== 'string' ||
            typeof length !== 'number' || content.length <= length || length <= 0) {
            result = content;
        } else if (after) {
            result = `...${ content.substr(content.length - length, length) }`;
        } else {
            result = `${ content.substr(0, length) }...`;
        }
        return result;
    },

    /**
     * [图片懒加载处理--添加loading图]
     * @param  {[String]} src    [当前的图片路径]
     */
    getLazyLoadImg(src) {
        return {
            src,
            loading: 'http://file.geeker.com.cn/uploadfile/ptisp/img/1583811342521/11.jpg'
        };
    },

    /**
     * [网页图标动态添加方法]
     * @param  {[String]} favicon [当前的图片路径]
     */
    setWebsiteFavicon(favicon) {
        if (!favicon) {
            return;
        }
        const link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    },

    /**
     * 路由匹配，处理动态路由是否匹配当前路由
     * 例如：/user/100匹配/user/:id
     * @param routerPath 动态路由/user/:id
     * @param target 实际路由/user/100
     * @return Boolean
     */
    matchRouter(routerPath, target) {
        // 带有冒号的为动态路由
        if (routerPath.includes(':')) {
            // 处理动态路由为正则表达式
            const reg = new RegExp(`^${ routerPath.replace(/:[a-zA-Z0-9]*/g, '[0-9a-zA-Z]+') }$`);
            return reg.test(target);
        } else {
            return routerPath === target;
        }
    },
    /**
     * 获取浏览器userAgent
     * @param paraStr 获取浏览器userAgent
     */
    isDaqsoftOs() {
        // 小写化
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.includes('daq-os');
    }
};
/* eslint-disable */
export default comm;