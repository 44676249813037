var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "flex flex-c wgs-guide-second font-30 col-fefeff" },
    [
      _vm.dialogVisible
        ? _c("div", [
            _c("iframe", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.loading,
                  expression: "loading",
                  modifiers: { fullscreen: true, lock: true },
                },
              ],
              staticClass: "frame",
              attrs: {
                id: "gameFrameparent",
                "element-loading-text": "正在加载，请稍后",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
                src: _vm.url,
                width: "100%",
                height: "100%",
              },
              on: {
                load: function ($event) {
                  _vm.loading = false
                },
              },
            }),
            _c(
              "div",
              {
                ref: "eventdetail",
                staticClass: "btn",
                on: {
                  mousedown: function ($event) {
                    return _vm.mouseDownHandleelse($event)
                  },
                  mouseup: function ($event) {
                    return _vm.mouseUpHandleelse($event)
                  },
                },
              },
              [
                _c(
                  "i",
                  { staticClass: "cp-icon", attrs: { title: "返回导览页" } },
                  [_vm._v("")]
                ),
              ]
            ),
          ])
        : [
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "1.5rem",
                  "margin-bottom": "0.7rem",
                },
              },
              [
                _c("img", {
                  staticClass: "flex-noshk",
                  attrs: { src: _vm.centerLogo.logoUrl },
                }),
              ]
            ),
            _c(
              "section",
              {
                staticClass:
                  "flex flex1 content flex-c flex-just-center flex-align-center",
              },
              [
                _c("section", { staticClass: "flex main" }, [
                  _vm.centerInfo.name
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-c flex-align-center flex-just-center center",
                          on: {
                            click: function ($event) {
                              return _vm.goUrl(
                                _vm.centerInfo.thirdUrl,
                                _vm.centerInfo.newWindowFlag,
                                _vm.centerInfo.name
                              )
                            },
                          },
                        },
                        [
                          _c("img", { attrs: { src: _vm.centerInfo.iconUrl } }),
                          _c("label", [_vm._v(_vm._s(_vm.centerInfo.name))]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "ul",
                    { staticClass: "flex" },
                    _vm._l(_vm.levelListFomart, function (list, index) {
                      return _c(
                        "section",
                        { key: index },
                        _vm._l(list, function (item, zindex) {
                          return _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    item.thirdUrl || item.name || item.iconUrl,
                                  expression:
                                    "item.thirdUrl || item.name || item.iconUrl",
                                },
                              ],
                              key: item.id,
                              staticClass:
                                "flex flex-c flex-align-center flex-just-center list",
                              style: {
                                background: _vm.colors[index * 2 + zindex],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goUrl(
                                    item.thirdUrl,
                                    item.newWindowFlag,
                                    item.name
                                  )
                                },
                              },
                            },
                            [
                              _c("img", { attrs: { src: item.iconUrl } }),
                              _c("label", [_vm._v(_vm._s(item.name))]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]),
                _vm.isWgs
                  ? _c("ul", { staticClass: "flex code" }, [
                      _vm._m(0),
                      _vm._m(1),
                      _vm._m(2),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "flex flex-c flex-align-center" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/wgs-second/code_yjy.png") },
      }),
      _c("label", [_vm._v("一机游")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "flex flex-c flex-align-center" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/wgs-second/code_app.png") },
      }),
      _c("label", [_vm._v("一机管APP下载")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "flex flex-c flex-align-center" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/wgs-second/code_wx.jpg") },
      }),
      _c("label", [_vm._v("官方微信")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }