<template>
  <div ref="dropdown"
       class="cp-site-dropdown">
    <div class="cp-site-dropdown__btn"
         @click="openList">
      <!-- <span>{{ isGroup ? site.companyName : site.scenicName }}</span> -->
      <span>{{ site.scenicShortName || site.companyName }}</span>
      <!-- <span>{{ site.scenicShortName }}</span> -->
      <i class="cp-icon cp-site-dropdown__after-icon">&#xe832;</i>
    </div>
    <el-dialog v-if="groups.length || scenic.length"
               ref="siteList"
               :visible.sync="showList"
               :modal="false"
               :modal-append-to-body="true"
               :close-on-click-modal="true"
               :show-close="false"
               top="0"
               custom-class="cp-site-list-dialog"
               @close="showList = false">
      <div class="cp-site-list-title">
        <span>切换</span>
        <!--        <i class="cp-icon"-->
        <!--           @click="openDefaultSitePanel">&#xea5e;</i>-->
      </div>
      <ul class="cp-site-dropdown-menu"
          :style="siteDropdownMenuStyle">
        <li v-if="groups.length"
            class="cp-site-dropdown-menu__group">
          <span class="cp-site-dropdown-menu__group-name">集团</span>
          <ul>
            <li v-for="item in groups"
                :key="`group-${item.id}-${item.accountId}`"
                :class="{'is-activesite': item.id === site.id && item.accountId === site.accountId}"
                class="cp-site-dropdown-menu__item site-dropdown-menu-item"
                @click="changeSite(item)">
              <p>{{ item.companyName }}</p>
              <p v-if="groupNum > 1">
                {{ item.empName }}
              </p>
            </li>
          </ul>
        </li>
        <li v-if="scenic.length"
            class="cp-site-dropdown-menu__group">
          <span class="cp-site-dropdown-menu__group-name">景区</span>
          <ul>
            <li v-for="item in scenic"
                :key="`scenic-${item.id}-${item.accountId}`"
                :class="{'is-activesite': item.id === site.id && item.accountId === site.accountId}"
                class="cp-site-dropdown-menu__item site-dropdown-menu-item"
                @click="changeSite(item)">
              <p>{{ item.scenicName }}</p>
              <p v-if="companyNum > 1">
                {{ item.empName }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </el-dialog>
    <el-dialog v-if="groups.length"
               ref="defaultSitePanel"
               :visible.sync="showDefaultSiteDialog"
               title="设置默认站点"
               :modal="false"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="true"
               top="0"
               custom-class="cp-default-site-panel"
               @close="showDefaultSiteDialog = false">
      <div class="cp-default-site-panel__content"
           :style="{maxHeight: defaultSitePanelContentMaxHeight}">
        <div class="cp-default-site-panel__group">
          <span class="cp-default-site-panel__group-title">集团</span>
          <el-radio v-for="item in groups"
                    :key="item.id"
                    v-model="site.id"
                    :label="item.id">
            {{ item.companyName }}
          </el-radio>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="showDefaultSiteDialog = false">取 消</el-button>
        <el-button type="primary"
                   @click="setDefaultSite">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 站点下拉
  name: 'CpSiteDropdown',
  props: {
    // 当前站点
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    // 集团站点列表
    groups: {
      type: Array,
      default() {
        return [];
      }
    },
    // 景区站点类白哦
    scenic: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认站点值
    defaultCompany: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      // 当前站点信息
      site: {
        id: ''
      },
      loading: false,
      // 是否显示站点列表
      showList: false,
      showDefaultSiteDialog: false,
      // 站点下拉菜单样式
      siteDropdownMenuStyle: {
        maxHeight: '500px'
      },
      // 默认站点对话框顶部距离样式
      defaultSiteDialogMarginTop: '10px',
      // 默认站点选择列表最大高度
      defaultSitePanelContentMaxHeight: '375px',
      maxHeight: 375
    };
  },
  computed: {
    isGroup() {
      return this.site.versionType === 1;
    },
    groupNum() {
      return this.groups.length;
    },
    companyNum() {
      return this.scenic.length;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.site = value;
      }
    }
  },
  created() {
    window.addEventListener('resize', () => {
      // TODO 处理节流
      if (this.showList) {
        this.setSiteListDialogStyle();
        this.setSiteDropdownMenuStyle();
      }

      if (this.showDefaultSiteDialog) {
        this.setDefaultSiteDialogStyle();
      }
    });
  },
  methods: {

    /**
     * 打开站点列表
     */
    openList() {
      this.showList = true;
      this.setSiteListDialogStyle();
      this.setSiteDropdownMenuStyle();
    },
    openDefaultSitePanel() {
      this.showDefaultSiteDialog = true;
      this.setDefaultSiteDialogStyle();
    },

    /**
     * 切换站点
     * @param site
     */
    changeSite(site) {
      // 切换到当前站点，忽略
      if (site.id === this.site.id && site.accountId === this.site.accountId) {
        return;
      }
      this.site = site;
      this.showList = false;
      // this.$store.commit('system/set', {
      //   favicon: '',
      //   name: '',
      //   logo: ''
      // });
      this.$store.commit('siteId', site.id);
      this.changeSitePost(site);
      // this.$store.commit('systemId', '');
      // this.$store.commit('refreshNum', false);
      // setTimeout(() => {
      //   this.$store.commit('refreshNum', true);
      // }, 100);
      // this.$nextTick(() => {
      //   // 修复iframe与浏览器token不一致问题

      //   const href = `${ location.href.split('#')[0] }#${ document.querySelector('iframe').src.split('#')[1] }`;
      //   if (href) {
      //     history.pushState(null, null, href);
      //   }
      // });
    },
    changeSitePost(site) {
      this.loading = true;
      // 切换站点，获取新的token
      this.$api
        .changeGroup({
          // authCode: this.$store.getters.authCode,
          accountId: site.accountId,
          vCode: site.vcode
        })
        .then((res) => {
          console.log('res: ', res);
          this.loading = false;
          const tokenInfo = res.data && res.data.token || {};
          if (tokenInfo.token) {
            if (res.data.guidePageFlag) {
              // alert('494')
              // 跳转到导览页
              this.$router.push({
                path: `/redirect?type=guide#${ tokenInfo.token }`
              });
            } else if (site.versionType === 2) {
              this.$router.push({
                path: `/redirect?type=index#${ tokenInfo.token }`
              });
            } else {
              window.location.href = `${ window.location.origin }/group/index#${ tokenInfo.token }`;
            }
          } else {
            this.$notice.operateError('切换站点失败', '获取token不存在');
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.data) {
            this.$notice.operateError('切换站点失败', error.data.message);
          } else {
            // 判断http code
            this.$notice.operateError('切换站点失败', '后台异常');
          }
        });
    },

    /**
     * 设置默认站点
     * @param site
     */
    setDefaultSite() {
      this.showDefaultSiteDialog = false;

      // TODO 设置默认站点
    },

    setSiteListDialogStyle() {
      this.$nextTick(() => {
        const left = this.$refs.dropdown.getBoundingClientRect().left;
        const dialog = this.$refs.siteList.$el.getElementsByClassName('cp-site-list-dialog')[0];
        if (dialog) {
          dialog.style.left = `${ left }px`;
        }
      });
    },
    setSiteDropdownMenuStyle() {
      this.siteDropdownMenuStyle.maxHeight = `${ window.innerHeight - 100 }px`;
    },
    setDefaultSiteDialogStyle() {
      this.$nextTick(() => {
        let maxHeight = window.innerHeight - 125;
        const diff = maxHeight - this.maxHeight;
        if (diff > 0) {
          this.defaultSiteDialogMarginTop = `${ diff / 2 }px`;
        } else {
          this.defaultSiteDialogMarginTop = 0;
        }

        maxHeight = this.maxHeight < maxHeight ? this.maxHeight : maxHeight;
        this.defaultSitePanelContentMaxHeight = `${ maxHeight }px`;

        const panel = this.$refs.defaultSitePanel.$el.getElementsByClassName('cp-default-site-panel')[0];
        if (panel) {
          panel.style.marginTop = this.defaultSiteDialogMarginTop;
        }
      });
    }
  }
};
</script>

<style lang="scss">
// 站点下拉
@include b(site-dropdown) {
  height: 50px;
  display: inline-flex;
  align-items: center;

  // 站点下拉按钮
  @include e(btn){
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    border: none;
    color: #fff;
  }

  @include e(prev-icon) {
    &.cp-icon {
      font-size: 14px;
      margin: 0 10px 0 25px;
    }
  }
  @include e(after-icon) {
    &.cp-icon {
      position: relative;
      display: inline-block;
      font-size: 12px;
      margin-right: 0;
      margin-left: 5px;
      transform: rotate(0deg);
      transition: transform .1s linear;
    }

    @include when(open) {
      top: -5px;
      transform: rotate(180deg);
      transition: transform .1s linear;
    }
  }
  .is-activesite{
    background-color: #4654eb;
    color: #fff;
    border-radius: 0px;
    &:hover{
      color: #ffffff;
    }
  }
}

// 切换站点的对话框
@include b(site-list-dialog) {
  &.el-dialog {
    position: absolute;
    top: 50px;
    margin-top: 0;
    border-radius: 0;
    width: auto;
    min-width: 160px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      background-color: #fff;
      border-radius: 0;
    }
  }
}

// 切换站点的列表
@include b(site-dropdown-menu) {
  padding: 10px 0;
  max-height: 500px;
  overflow-y: auto;

  @include e(group) {
    cursor: default;

    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }

    @include e(group-name) {
      padding: 0 20px;
      font-size: 16px;
      color: #909399;
      cursor: default;
    }

    @include e(item) {
      min-height: 42px;
      padding: 5px 20px;
      line-height: 32px;
      font-size: 14px;
      color: #666;
      cursor: pointer;
      p{
        line-height: 20px;
      }
      @include when(active) {
        color: $primary;
      }
      &:hover {
        background-color: #4654eb;
        color: #fff;
        border-radius: 0px;
      }
    }
  }
}

// 默认站点按钮
@include b(site-list-title) {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  background-color: $--color-primary-light-9;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  color: #666;
  cursor: default;

  > .cp-icon {
    font-size: 12px;
    cursor: pointer;
  }
}

// 默认站点设置面板
@include b(default-site-panel) {
  &.el-dialog {
    width: 460px;
    border-radius: 5px;
    overflow: hidden;

    .el-dialog__header {
      height: 45px;
      line-height: 45px;
      padding: 0 0 0 20px;
      font-size: 16px;
      color: #666;
      background-color: #f2f6fc;

      .el-dialog__headerbtn {
        top: 0;
      }
    }

    .el-dialog__body {
      padding: 10px 30px;
      background-color: #fff;
      border-radius: 0;
      max-height: 375px;
      border-bottom: 1px solid #e8e8e8;
      overflow-y: auto;
    }
  }

  // 站点分组
  @include e(group) {
    display: flex;
    flex-direction: column;

    @include e(group-name) {
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      color: #909399;
      cursor: default;
    }

    .el-radio {
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>

<style lang="scss" scoped>
.cp-site-dropdown{
  .site-dropdown-menu-item{
    max-width: 300px;
    overflow: hidden;
    span{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

</style>
