import { ajax } from '@/utils';

export default {
  // 查询开关和平铺状态
  guidePageConfig() {
    return ajax.get('/setting/guide-page/config');
  },
  // 查询平铺显示-引导页数据(仅启用)
  guidePageFlatList(params) {
    return ajax.get('/setting/guide-page/flat-list', params);
  },
  // 查询层级显示-引导页数据(仅启用)
  guidePageLevelList(params) {
    return ajax.get('/setting/guide-page/level-list', params);
  },
  // 查询引导页数据的中心(仅启用)
  guidePageCenter(params) {
    return ajax.get('/setting/guide-page/center', params);
  },
  // 查询logo和标题
  guidePageLogo() {
    return ajax.get('/setting/guide-page/logo');
  },
  // 综合大屏 -- 查询主题皮肤配置
  getTheme() {
    return ajax.get('/setting/integrated-screen/theme');
  },
  // 查询logo和标题
  getIntegratedScreenlogo() {
    return ajax.get('/setting/integrated-screen/logo');
  },
  // 查询综合大屏配置菜单数据(仅启用)
  getIntegratedScreenMenuList(params) {
    return ajax.get('/setting/integrated-screen/menu-list', params);
  },
  // 更新导览页配置
  updateGuideTheme(params) {
    return ajax.put('/setting/guide-page/update-theme', params);
  },
  // 查询主题皮肤配置
  getGuideTheme() {
    return ajax.get('/setting/guide-page/theme');
  }
};
