var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%", position: "relative" } },
    [
      _c("svg", { staticClass: "path1", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.247, Q57.1583 22.3,65.56666 21.7",
          },
        }),
      ]),
      _c("svg", { staticClass: "path3", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "pathRt2",
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.247, Q57.1583 22.3,65.56666 21.7 M65.56666 21.7,Q72.65 21.3,82.18326 19.5",
          },
        }),
      ]),
      _c("svg", { staticClass: "path5", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "pathRt3",
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.247, Q57.1583 22.3,65.56666 21.7 M65.56666 21.7,Q72.65 21.3,82.18326 19.5 M82.18326 19.5, C91.5 17.5,100 14, 94.7 8 ",
          },
        }),
      ]),
      _c("svg", { staticClass: "path2", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "pathLt1",
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.25,Q41.5417 22.3,33.2334 21.6 ",
          },
        }),
      ]),
      _c("svg", { staticClass: "path4", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "pathLt2",
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.25,Q41.5417 22.3,33.2334 21.6 M33.2334 21.6, Q24.8 21,16.6166 19.258",
          },
        }),
      ]),
      _c("svg", { staticClass: "path6", attrs: { viewBox: "0 0 99.7 23.6" } }, [
        _c("path", {
          staticClass: "pathLt3",
          attrs: {
            fill: "none",
            stroke: "",
            d: "M49.85 22.25,Q41.5417 22.3,33.2334 21.6 M33.2334 21.6, Q24.8 21,16.6166 19.258 M16.6166 19.258, C5.5 16.7,0.5 13.2,5 8 ",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }