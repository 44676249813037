var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "anime-box",
      style: [
        { width: _vm.clientWidth + "px" },
        { height: _vm.clientHeight + "px" },
      ],
    },
    [
      _c("anime-bg"),
      _vm._m(0),
      _c("lottie", {
        staticClass: "lottieinfo",
        attrs: {
          options: _vm.defaultOptions,
          height: _vm.clientHeight,
          width: _vm.clientWidth,
        },
      }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("anime-svg"),
          _c("anime-svg-shoot"),
          _c("anime-rocket", { attrs: { "center-info": _vm.centerInfo } }),
          _c("anime-menu-first", {
            attrs: { "level-list": _vm.navlist },
            on: { firstIconClick: _vm.firstIconClick },
          }),
          _c("anime-menu-child", {
            ref: "menuChild",
            attrs: { "shoot-runing": _vm.shootRuning },
          }),
          _c("img", {
            staticClass: "shooticon",
            style: { visibility: _vm.shootRuning ? "visible" : "hidden" },
            attrs: { src: require("../../../assets/images/wgs/dz.png") },
          }),
          _c("anime-planet"),
          _c("anime-light-circle"),
        ],
        1
      ),
      _c("anime-particle"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: { src: require("../../../assets/images/wgs/txt.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }